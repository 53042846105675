import React, { useState, useEffect, SyntheticEvent } from "react"
import {
  Grid,
  Box,
  Typography,
  Button,
  FormHelperText,
  Stack
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import moment from "moment"

import TransferList from "../TransferList"
import { useAuthContext } from "../../context/AuthContext"
import { categories } from "../../api"
import ViewScoreCard from "./ViewScoreCard"
import { getUserData, isCompanyUser } from "../../utils/util"

const ScoreCard = () => {
  const { getCompanyList, getScorecardData } = useAuthContext()
  const [errors, setErrors] = useState<any>()
  const [companyList, setCompanyList] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [middleDate, setMiddleMonth] = useState<any>(null)
  const [endDate, setEndDate] = useState(null)
  const [distributors, setDistributors] = useState([])
  const [kpiList, setKpiList] = useState<any>();
  const [scorecardData, setScorecardData] = useState(null)
  const [viewScorecardData, setViewScorecardData] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(categories[0].id)
  const userCompany = JSON.parse(localStorage.getItem("company") as string);
  const userData = getUserData();
  const userType = userData?.user_type;
  const companyUser = isCompanyUser(userType)
  const { getKpiList } =
    useAuthContext()


  useEffect(() => {
    getKpiList({}).then((resp: any) => {
      const approvedKpis = resp?.filter((item: any) => item.status === 2)
      setKpiList(approvedKpis)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getCompanyList().then((resp: any) => {
      let companyListData = resp;
      if (companyUser) {
        companyListData = companyListData?.map((item: any) => {
          if (item?.name !== userCompany) {
            item.name = item?.id;
          }
          return item;
        })?.sort((a: any, b: any) =>
          (a?.name === userCompany || b?.name === userCompany)
            ? -1
            : a?.id - b?.id
        )
      }

      setCompanyList(companyListData)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (startDate && endDate) {
      const momentStartDate = moment(startDate)
      const momentEndDate = moment(endDate)
      const dateErrors = { date_range: "" }
      const monthDiff = momentEndDate.diff(momentStartDate, "months")

      if (momentStartDate.format("x") > momentEndDate.format("x")) {
        dateErrors.date_range = "Start date must be earlier than end date"
        setMiddleMonth(null)
      } else if (monthDiff < 2 || monthDiff > 11) {
        dateErrors.date_range =
          "You must select minimum 3 of months and maximum 12 months date range"
        setMiddleMonth(null)
      } else {
        const midMonth = momentStartDate.add(Math.floor(monthDiff / 2), "months")
        setMiddleMonth(midMonth)
      }
      setErrors((prev: any) => ({ ...(prev || {}), ...dateErrors }))
    }
  }, [startDate, endDate])

  const handleGenerateScoreCard = (e: SyntheticEvent, category: any = "") => {
    setViewScorecardData(true)
    getScorecardData({
      start_date: moment(startDate).format("01-MM-YYYY"),
      middle_date: moment(middleDate).format("01-MM-YYYY"),
      end_date: moment(endDate).endOf("month").format("DD-MM-YYYY"),
      distributors: distributors?.map((item: any) => item?.id)?.join(","),
      category: category || selectedCategory
    }).then((resp: any) => {
      setScorecardData(resp)
    })
  }

  const handleStartMonthChange = (value: any) => {
    setStartDate(value)
  }

  const handleEndMonthChange = (value: any) => {
    setEndDate(value)
  }

  const handleDistributorsChange = (distributorsList: any) => {
    setErrors({ ...(errors || {}), distributors: "" })
    if (!distributorsList?.length) {
      setErrors({
        ...(errors || {}),
        distributors: "Please select at least 1 distributor."
      })
    }

    setDistributors(distributorsList)
  }

  const allowSubmit = () => {
    const hasError = Object.keys(errors || {})?.some((type: any) => errors?.[type])
    return Boolean(
      !hasError &&
      startDate &&
      endDate &&
      middleDate &&
      selectedCategory &&
      distributors?.length
    )
  }

  const handleReset = () => {
    setViewScorecardData(false)
    setStartDate(null)
    setEndDate(null)
    setMiddleMonth(null)
    setDistributors([])
    setSelectedCategory(categories?.[0]?.id)
    setScorecardData(null)
  }

  return (
    <Box
      sx={{
        px: 2,
        ml: 30,
        mr: 5,
        mt: 15,
        mb: 2,
        display: "flex",
        flexDirection: "column",
        gap: "1rem"
      }}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          mt={1}
          variant="h2"
          component="h2"
          sx={{ fontSize: "2rem", textAlign: "left" }}
        >
          {viewScorecardData ? "View Scorecard" : "Generate Scorecard"}
        </Typography>
        {viewScorecardData && (
          <Button
            variant="contained"
            sx={{
              background: "#140A9A",
              color: "#FFFFFF",
              "&.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26)",
                boxShadow: "none",
                backgroundColor: "rgba(0, 0, 0, 0.12)"
              }
            }}
            onClick={handleReset}
          >
            New scorecard
          </Button>
        )}
      </Stack>
      {viewScorecardData ? (
        <ViewScoreCard
          startDate={startDate}
          middleDate={middleDate}
          endDate={endDate}
          scorecardData={scorecardData}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          generateScoreCard={handleGenerateScoreCard}
          kpiList={kpiList}
        />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ fontSize: "1rem" }}
              >
                <strong>Step 1:</strong> Select Start Month and End Month
              </Typography>
              <Typography
                variant="caption"
                component="div"
                sx={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.6)" }}
              >
                Data will be shown for start month, middle month and end month
                (3 months total). You must select minimum 3 months and maximum 12
                months date range.
              </Typography>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label={"Select Start Month"}
                    value={startDate}
                    onChange={handleStartMonthChange}
                    views={["month", "year"]}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label={"Select End Month"}
                    value={endDate}
                    onChange={handleEndMonthChange}
                    views={["month", "year"]}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: '0 !important' }}>
                {errors?.date_range && (
                  <FormHelperText error>{errors?.date_range}</FormHelperText>
                )}
                {middleDate && (
                  <FormHelperText sx={{ color: '#0000EE' }}>
                    Data will be shown for {moment(startDate).format("MMMM YYYY")}
                    , {moment(middleDate).format("MMMM YYYY")} and{" "}
                    {moment(endDate).format("MMMM YYYY")}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ fontSize: "1rem" }}
              >
                <strong>Step 2:</strong> Add Distributors
              </Typography>
              <Typography
                variant="caption"
                component="div"
                sx={{
                  fontSize: "0.75rem",
                  color: "rgba(0, 0, 0, 0.6)"
                }}
              >
                Compare Distributor KPIs by selecting upto five below and adding
                them to the list on the right.
              </Typography>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <TransferList
                itemsList={companyList}
                selectionLimit={5}
                setSelectedList={handleDistributorsChange}
              />
              {errors?.distributors && (
                <FormHelperText error>{errors?.distributors}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs="auto">
              <Button
                variant="contained"
                sx={{ background: "#F8F9FC", color: "#000000" }}
                onClick={handleReset}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="contained"
                sx={{
                  background: "#140A9A",
                  color: "#FFFFFF",
                  "&.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.26)",
                    boxShadow: "none",
                    backgroundColor: "rgba(0, 0, 0, 0.12)"
                  }
                }}
                onClick={handleGenerateScoreCard}
                disabled={!allowSubmit()}
              >
                Generate scorecard
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  )
}

export default ScoreCard

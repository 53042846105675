import {
  Box,
  Button,
  Grid,
  SelectChangeEvent,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  IconButton,
  Autocomplete
} from "@mui/material"
import { useAuthContext } from "../../context/AuthContext"
import { useEffect, useState } from "react"
import { ReactComponent as InfoIcon } from "../../assests/images/info.svg"
import { ReactComponent as TickIcon } from "../../assests/images/tick.svg"
import { useNavigate } from "react-router-dom"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { checkShowCompanyAndShowCustomer, getSubKpiColor, getSubKpiDataType, getSubKpiType, getUserData, isCompanyUser } from "../../utils/util"
import { categories } from "../../api"

const GenerateChart = () => {
  const { getKpiList, getCompanyList, getCustomerList } =
    useAuthContext()
  const userData = getUserData();
  const userType = userData?.user_type;

  const nav = useNavigate()
  const [kpiList, setKpiList] = useState([] as any)
  const [companyList, setCompanyList] = useState([] as any)
  const [customerList, setCustomerList] = useState([])
  const [company, setCompany] = useState([])
  const [customer, setCustomer] = useState([])
  const [selectedSubKpis, setSelectedSubKpis] = useState([] as any)
  const [showSelectCompany, setShowSelectCompany] = useState(false)
  const [showSelectCustomer, setShowSelectCustomer] = useState(false)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [kpi, setKpi] = useState("")
  const [selectedKpi, setSelectedKpi] = useState({} as any)
  const [targettedSubKpi, setTargettedSubKpi] = useState([] as any)
  const [category, setCategory] = useState("");
  const userCompany = JSON.parse(localStorage.getItem("company") as string);

  const handleItemClick = (subKpi: any) => {
    if (selectedSubKpis.includes(subKpi)) {
      // to unselect the item
      setSelectedSubKpis(selectedSubKpis.filter((item: any) => item?.id !== subKpi?.id))
      setShowSelectCompany(false)
      setShowSelectCustomer(false)
      setCompany([])
      setCustomer([])
      setTargettedSubKpi(targettedSubKpi?.filter((item: any) => item?.id !== subKpi?.id) || [])
    } else {
      setSelectedSubKpis([...selectedSubKpis, subKpi])
    }
  }

  useEffect(() => {
    category && getKpiList({ category }).then((resp: any) => {
      const approvedKpis = resp?.filter((item: any) => item.status === 2)
      setKpiList(approvedKpis)
    })
  }, [category])

  useEffect(() => {
    const selectedKpiItem =
      kpiList && kpiList.filter((item: any) => item.id === kpi)
    setSelectedKpi(selectedKpiItem[0])
  }, [kpi])

  useEffect(() => {
    getCompanyList().then((resp: any) => {
      setCompanyList(resp)
    })
    getCustomerList().then((resp: any) => {
      setCustomerList(resp)
    })
  }, [])

  useEffect(() => {
    selectedSubKpis?.length &&
      selectedSubKpis.map((subKpi: any) => {
        const [showCompany, showCustomer] = checkShowCompanyAndShowCustomer(
          selectedKpi,
          subKpi,
          userType
        );

        showCompany && setShowSelectCompany(showCompany);
        showCustomer && setShowSelectCustomer(showCustomer);
      })
  }, [userType, selectedKpi, selectedSubKpis])

  const handleCompanyChange = (event: any, value: any) => {
    setCompany(value)
  }

  const handleCustomerChange = (event: any, value: any) => {
    setCustomer(value)
  }

  const handleStartDateChange = (value: any) => {
    setStartDate(value);
  };

  const handleEndDateChange = (value: any) => {
    setEndDate(value);
  };

  const handleKpi = (event: SelectChangeEvent) => {
    setKpi(event.target.value)
  }

  const handleTarget = (event: any, value: any) => {
    setTargettedSubKpi(value)
  }

  const handleCategory = (event: SelectChangeEvent) => {
    setSelectedSubKpis([])
    setKpi("")
    setCompany([])
    setCustomer([])
    setShowSelectCompany(false)
    setShowSelectCustomer(false)
    setCategory(event.target.value)
  }

  const handleReset = () => {
    setCategory('')
    setSelectedSubKpis([])
    setKpi("")
    setCompany([])
    setCustomer([])
    setStartDate("")
    setEndDate("")
    setShowSelectCompany(false)
    setShowSelectCustomer(false)
  }

  const handleNextBtn = () => {
    const startDateObject = new Date(startDate);
    const startYear = startDateObject.getFullYear();
    const startMonth = String(startDateObject.getMonth() + 1).padStart(2, "0");
    const formattedStartDate = `${startYear}-${startMonth}-01`;

    const endDateObject = new Date(endDate);
    const endYear = endDateObject.getFullYear();
    const endMonth = String(endDateObject.getMonth() + 1).padStart(2, "0");
    const formattedEndDate = `${endYear}-${endMonth}-01`;
    const userCompanyInfo = isCompanyUser(userType) && companyList?.find(
      (item: any) => (item?.name === userCompany)
    );

    nav(`/view-chart`, {
      state: {
        selectedKpi,
        kpiId: selectedKpi.id,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        selectedSubKpiIds: selectedSubKpis?.map((obj: any) => obj.id),
        company: isCompanyUser(userType) && userCompanyInfo ? [userCompanyInfo] : company,
        customer: customer,
        target: targettedSubKpi,
        selectedSubKpis: selectedSubKpis,
      }
    })
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 2,
          ml: 30,
          mr: 5,
          mt: 15,
          mb: 2,
          backgroundColor: "#F8F9FC"
        }}
      >
        <Grid container display={"flex"} flexDirection={"row"} sx={{ mt: 1 }}>
          <Grid item xs={3} display={'flex'} justifyContent={'flex-start'}>
            <h2>KPI Report</h2>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="category-select-label">Select Category</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={category}
                label="Select Category"
                size="small"
                style={{
                  marginBottom: 0,
                  borderColor: "#FFFFFF",
                  background: "#FFFFFF"
                }}
                onChange={handleCategory}
              >
                {categories &&
                  categories.map((category: any) => {
                    return (
                      <MenuItem value={category.id}>
                        {category.label}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} mt={2}>
            <FormControl fullWidth>
              <InputLabel id="kpi-select-label">Select KPI</InputLabel>
              <Select
                labelId="kpi-select-label"
                id="kpi-select"
                value={kpi}
                label="Select KPI"
                size="small"
                style={{
                  marginBottom: 0,
                  borderColor: "#FFFFFF",
                  background: "#FFFFFF"
                }}
                onChange={handleKpi}
              >
                {kpiList &&
                  kpiList.map((kpi: any) => {
                    return (
                      <MenuItem value={kpi.id}>
                        {kpi.performance_indicator}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={1} display={"flex"} alignItems={"flex-start"} sx={{ mt: 2 }}>
            <InputLabel>Sub KPI</InputLabel>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ p: 2, border: '1px solid #AFAF9B', borderRadius: "3px", background: "#FFFFFF" }}>
              <Typography>
                <Grid container spacing={2} alignItems="center">
                  {selectedKpi && selectedKpi?.sub_kpi_details?.length
                    ? selectedKpi?.sub_kpi_details?.map(
                      (subKpi: any, index: any) => {
                        const disabled = (selectedSubKpis?.length && selectedSubKpis?.[0]?.data_type !== subKpi?.data_type) ? true : false;
                        const type = getSubKpiType(subKpi.type)

                        return <Grid item xs={4} key={index}>
                          <Typography
                            onClick={() => !disabled && handleItemClick(subKpi)}
                            sx={{ mt: 1, mb: 1 }}
                            style={{
                              cursor: disabled ? "not-allowed" : "pointer",
                              opacity: disabled ? 0.5 : 1,
                              borderRadius: 4,
                              color: selectedSubKpis.includes(subKpi)
                                ? "#FFFFFF"
                                : "#000000",
                              backgroundColor: selectedSubKpis.includes(
                                subKpi
                              )
                                ? "#140A9A"
                                : getSubKpiColor(subKpi)
                            }}
                          >
                            {selectedSubKpis.includes(subKpi) ? (
                              <TickIcon />
                            ) : null}{" "}
                            {subKpi.name}
                            {
                              <Tooltip title={<>
                                {disabled && (
                                  <>
                                    <div style={{ color: "red", background: 'white' }}>
                                      <b>Selection disabled. You can select sub kPIs with same data type to generate chart</b>
                                    </div>
                                    <br />
                                  </>
                                )}
                                <div>
                                  <b>Sub KPI Name: {subKpi.name}</b>
                                </div>

                                <div>
                                  Type: {type}
                                </div>
                                <br />
                                <div>
                                  Data Type: {getSubKpiDataType(subKpi.data_type)}
                                </div>
                                <br />
                                <div>
                                  Description: {subKpi.explanatory_text}
                                </div>
                              </>}>
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </Typography>
                        </Grid>
                      }
                    )
                    : null}
                </Grid>
              </Typography>
            </Box>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs="auto">
                  <Box
                    sx={{
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                      backgroundColor: "#DDDDDD"
                    }}
                  ></Box>
                </Grid>
                <Grid
                  item
                  xs="auto"
                  display={"flex"}
                  justifyContent={"flex-start"}
                >
                  <Typography variant="caption" mr={3}>Overall</Typography>
                </Grid>
                <Grid item xs="auto">
                  <Box
                    sx={{
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                      backgroundColor: "#9BB9B9"
                    }}
                  ></Box>
                </Grid>
                <Grid
                  item
                  xs="auto"
                >
                  <Typography variant="caption" mr={3}>Company Specific</Typography>
                </Grid>
                <Grid item xs="auto">
                  <Box
                    sx={{
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                      backgroundColor: "#AFAF9B"
                    }}
                  ></Box>
                </Grid>
                <Grid
                  item
                  xs="auto"
                >
                  <Typography variant="caption" mr={3}>Customer Specific</Typography>
                </Grid>
                <Grid item xs="auto">
                  <Box
                    sx={{
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                      backgroundColor: "#140A9A"
                    }}
                  ></Box>
                </Grid>
                <Grid
                  item
                  xs="auto"
                >
                  <Typography variant="caption">Selected</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {showSelectCompany ? (
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={companyList || []}
                getOptionLabel={(option: any) => option?.name}
                value={company || []}
                onChange={handleCompanyChange}
                filterSelectedOptions
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select Company"
                    placeholder="Select Company"
                    style={{ background: "#FFFFFF" }}
                  />
                )}
              />
            </Grid>
          ) : null}
          {showSelectCustomer ? (
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={customerList || []}
                getOptionLabel={(option: any) => option?.name}
                value={customer || []}
                onChange={handleCustomerChange}
                filterSelectedOptions
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select Customer"
                    placeholder="Select Customer"
                    style={{ background: "#FFFFFF" }}
                  />
                )}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Grid container display={"flex"} sx={{ mt: 1 }}>
              <Grid item xs={6} sx={{ pr: 1 }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Box width="100%" style={{ background: "#FFFFFF" }}>
                    <DatePicker
                      label={"Select Start Month"}
                      value={startDate || null}
                      onChange={handleStartDateChange}
                      views={["month", "year"]}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} style={{ background: "#FFFFFF" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label={"Select End Month"}
                    value={endDate || null}
                    onChange={handleEndDateChange}
                    views={["month", "year"]}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>

            <Autocomplete
              multiple
              options={selectedSubKpis || []}
              getOptionLabel={(option: any) => option?.name}
              value={targettedSubKpi || []}
              onChange={handleTarget}
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select Target (From Sub Kpi Template)"
                  placeholder="Select Target (From Sub Kpi Template)"
                  style={{ background: "#FFFFFF" }}
                />
              )}
            />
          </Grid>
          <Grid container display={"flex"} sx={{ mt: 2, mb: 4 }}>
            <Grid
              item
              xs={6}
              sx={{ pr: 1 }}
              justifyContent={"flex-end"}
              display={"flex"}
            >
              <Button
                onClick={handleReset}
                variant="contained"
                style={{ background: "#F8F9FC", color: "#000000" }}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={6} display={"flex"}>
              <Button
                onClick={handleNextBtn}
                variant="contained"
                style={{ background: "#000099" }}
                disabled={
                  !selectedKpi ||
                  !selectedSubKpis.length ||
                  (showSelectCompany && !company.length) ||
                  (showSelectCustomer && !customer.length) ||
                  !startDate || !endDate
                }
                classes={{ disabled: "custom-disabled-button" }}
              >
                Generate Chart
              </Button>{" "}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default GenerateChart

import { useState } from "react"
import { ReactComponent as AppLogo } from "../../assests/images/AppLogo.svg"
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
  createTheme
} from "@mui/material"
import { useAuthContext } from "../../context/AuthContext"
import { useNavigate } from "react-router-dom"
import "./SignIn.css"

const ForgotPassword = () => {
  const theme = createTheme({
    palette: {
      secondary: {
        main: "#000099"
      }
    }
  })

  const { resetSendEmailOtp } = useAuthContext()
  const [email, setEmail] = useState("")
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const nav = useNavigate()

  const handleChange = (event: any) => {
    setEmail(event.target.value)
  }

  const handleSendEmailOtp = async (event: any) => {
    event.preventDefault()
    // Validate the form inputs
    const validationErrors: { [key: string]: string } = {}
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (email.trim() === "") {
      validationErrors.username = "Email is required"
    } else if (!emailRegex.test(email)) {
      validationErrors.username = "Invalid email format"
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
    } else {
      resetSendEmailOtp({ email }).then(() =>
        nav("/verify-otp", {
          state: { email: email, from: "forgot-password" }
        })
      )
    }
  }

  return (
    <>
      <div className="page-container">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            px: 2
          }}
        >
          <Paper
            sx={{
              p: 2,
              textAlign: "center",
              maxWidth: "400px",
              width: "100%"
            }}
            style={{
              borderColor: "#000099",
              borderWidth: 2,
              borderStyle: "solid",
              borderRadius: 8
            }}
          >
            <div className="grid-container">
              <Grid
                container
                spacing={2}
                direction={"column"}
                justifyItems={"center"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <AppLogo />
                  <Box className="sign-in-title">Forgot Password</Box>
                  <Typography
                    sx={{ mt: 2, mr: 10 }}
                    display={"flex"}
                    style={{ fontWeight: 400, fontSize: "16px" }}
                  >
                    {"Kindly provide your email address."}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email I'd"
                    className="sign-in-field"
                    variant="outlined"
                    size="small"
                    style={{ width: 320, marginTop: 0 }}
                    value={email}
                    onChange={handleChange}
                    margin="normal"
                    error={!!errors.username}
                    helperText={errors.username}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ThemeProvider theme={theme}>
                    <Button
                      variant={"contained"}
                      color="secondary"
                      onClick={handleSendEmailOtp}
                      style={{
                        width: 320,
                        color: "#FFFFFF",
                        textTransform: "none"
                      }}
                    >
                      Send verification code
                    </Button>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Box>
      </div>
    </>
  )
}

export default ForgotPassword

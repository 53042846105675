import React, { useEffect, useState } from "react"
import { ReactComponent as AppLogo } from "../../assests/images/AppLogo.svg"
import EyeOn from "../../assests/images/eye_on.svg"
import EyeOff from "../../assests/images/eye_off.svg"
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  ThemeProvider,
  createTheme
} from "@mui/material"
import { useAuthContext } from "../../context/AuthContext"
import { useNavigate } from "react-router-dom"
import "../SignIn/SignIn.css"

const AddProfile = () => {
  const [rememberMe, setRememberMe] = useState(false)
  const [region, setRegion] = React.useState("")
  const nav = useNavigate()
  const { getRegions, regions, confirmEmailOtpResp, userRegister } =
    useAuthContext()

  const handleRegion = (event: SelectChangeEvent) => {
    setRegion(event.target.value)
  }

  const theme = createTheme({
    palette: {
      secondary: {
        main: "#000099"
      }
    }
  })

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    createPassword: "",
    confirmPassword: "",
    showCreatePassword: false,
    showConfirmPassword: false
  })

  const [errors, setErrors] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    getRegions()
  }, [])

  const handleClickShowCreatePassword = () =>
    setValues({ ...values, showCreatePassword: !values.showCreatePassword })
  const handleMouseDownCreatePassword = (event: any) => event.preventDefault()

  const handleClickShowConfirmPassword = () =>
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword })
  const handleMouseDownConfirmPassword = (event: any) => event.preventDefault()

  const handleChange =
    (prop: string) => (event: { target: { value: any } }) => {
      setValues({ ...values, [prop]: event.target.value })
    }

  const handleSignUp = async (event: any) => {
    event.preventDefault()
    // Validate first Name
    const validationErrors: { [key: string]: string } = {}
    const firstNameRegex = /^[a-zA-Z]{2,30}$/
    if (values.firstName.trim() === "") {
      validationErrors.firstName = "First Name is required"
    } else if (!firstNameRegex.test(values.firstName)) {
      validationErrors.firstName = "Invalid First Name"
    }

    // Validate last Name
    if (values.lastName.trim() === "") {
      validationErrors.lastName = "First Name is required"
    }

    // Create Password validation
    if (values.createPassword.trim() === "") {
      validationErrors.createPassword = "Password is required"
    } else if (values.createPassword.length < 8) {
      validationErrors.createPassword =
        "Password should be at least 8 characters long"
    }

    // Confirm Password validation
    if (values.createPassword !== values.confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match."
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
    } else {
      let payload: any = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: confirmEmailOtpResp.email,
        password: values.confirmPassword
      }
      if (region) {
        payload = { ...payload, region: region }
      }
      userRegister(payload).then(() => nav("/register-success"))
    }
  }

  const handleRememberMe = (event: any) => {
    setRememberMe(event.target.checked)
  }

  return (
    <>
      <div className="page-container">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            px: 2
          }}
        >
          <Paper
            sx={{
              textAlign: "center",
              maxWidth: "400px",
              width: "100%"
            }}
            style={{
              borderColor: "#000099",
              borderWidth: 2,
              borderStyle: "solid",
              borderRadius: 8
            }}
          >
            <AppLogo />
            <Box className="sign-in-title" sx={{ mb: 2 }}>
              Add Profile
            </Box>
            <div className="grid-container">
              <Grid
                container
                direction={"column"}
                justifyItems={"center"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="region-select-label">Region</InputLabel>
                    <Select
                      labelId="region-select-label"
                      id="region-select"
                      value={region}
                      label="Region"
                      size="small"
                      style={{
                        width: 330,
                        marginBottom: 0,
                        borderColor: "#FFFFFF"
                      }}
                      onChange={handleRegion}
                    >
                      {regions &&
                        regions.map((reg: any) => {
                          return <MenuItem value={reg.id}>{reg.name}</MenuItem>
                        })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email I'd"
                    className="sign-in-field"
                    variant="outlined"
                    size="small"
                    style={{ width: 330, marginBottom: 0 }}
                    value={confirmEmailOtpResp.email}
                    aria-readonly="true"
                    margin="normal"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Company"
                    className="sign-in-field"
                    variant="outlined"
                    size="small"
                    style={{ width: 330, marginBottom: 0 }}
                    value={confirmEmailOtpResp.company_name}
                    aria-readonly="true"
                    margin="normal"
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="First name"
                    className="sign-in-field"
                    variant="outlined"
                    size="small"
                    style={{ width: 330, marginBottom: 0 }}
                    value={values.firstName}
                    onChange={handleChange("firstName")}
                    margin="normal"
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Last name"
                    className="sign-in-field"
                    variant="outlined"
                    size="small"
                    style={{ width: 330, marginBottom: 0 }}
                    value={values.lastName}
                    onChange={handleChange("lastName")}
                    margin="normal"
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Create Password"
                    variant="outlined"
                    className="sign-in-field"
                    size="small"
                    style={{
                      width: 330,
                      marginBottom: 0,
                      borderColor: "#FFFFFF"
                    }}
                    type={values.showCreatePassword ? "text" : "password"}
                    value={values.createPassword}
                    onChange={handleChange("createPassword")}
                    margin="normal"
                    error={!!errors.createPassword}
                    helperText={errors.createPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowCreatePassword}
                            onMouseDown={handleMouseDownCreatePassword}
                            edge="end"
                          >
                            {values.showCreatePassword ? (
                              <img src={EyeOn} />
                            ) : (
                              <img src={EyeOff} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Confirm Password"
                    variant="outlined"
                    className="sign-in-field"
                    size="small"
                    style={{
                      width: 330,
                      marginBottom: 0,
                      borderColor: "#FFFFFF"
                    }}
                    type={values.showConfirmPassword ? "text" : "password"}
                    value={values.confirmPassword}
                    onChange={handleChange("confirmPassword")}
                    margin="normal"
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                            edge="end"
                          >
                            {values.showConfirmPassword ? (
                              <img src={EyeOn} />
                            ) : (
                              <img src={EyeOff} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} className="grid-item" sx={{ ml: 1, pl: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rememberMe}
                        onChange={handleRememberMe}
                        style={{ paddingRight: "0px" }}
                      />
                    }
                    label={
                      <span
                        style={{
                          fontSize: "14px",
                          letterSpacing: "-0.009375rem"
                        }}
                      >
                        I agree to the{" "}
                        <a
                          href="https://www.komatsu.com/en/terms-of-use/"
                          target="_blank"
                        >
                          Terms &amp; Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://www.komatsu.com/en/privacy-statement/"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                      </span>
                    }
                  />
                </Grid>
              </Grid>
            </div>

            <Grid
              container
              spacing={2}
              direction={"column"}
              alignItems={"center"}
            >
              <Grid item xs={12}>
                <ThemeProvider theme={theme}>
                  <Button
                    variant={"contained"}
                    sx={{ mb: 2 }}
                    color="secondary"
                    onClick={handleSignUp}
                    style={{
                      width: 330,
                      color: "#FFFFFF",
                      textTransform: "none"
                    }}
                    disabled={!rememberMe}
                  >
                    Register
                  </Button>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </div>
    </>
  )
}

export default AddProfile

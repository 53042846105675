import { Box, Button, Grid } from "@mui/material"
import { useAuthContext } from "../../context/AuthContext"
import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { Line } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement
} from "chart.js"
import type { ChartData, ScatterDataPoint } from "chart.js"
import "chartjs-adapter-moment"
import html2canvas from "html2canvas"
import { ReactComponent as ShareIcon } from "../../assests/images/share.svg"
import { toast } from "react-toastify"
import { checkShowCompanyAndShowCustomer, getFillRate, getMonthsInPeriod, getSubKpiDataType, getUserData, processReportData, isDecimalFillRate } from "../../utils/util"
import moment from "moment"
import { DATA_TYPE_DAYS, DATA_TYPE_PERCENTAGE, DATA_TYPE_RECORDS } from "../../api"
import { APPCONFIG } from "../../app-config"
import Loader from '../Loader'

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement
)

const ViewDashboard = () => {
  const captureRef = useRef(null)
  const { postDashboard, uploadChartImage } = useAuthContext()
  const location = useLocation()
  const [responseData, setResponseData] = useState<any[]>([])
  const [arrResponseData, setArrResponseData] = useState<any[]>([]);
  const [selectedSubKpis, setSelectedSubKpis] = useState<any[]>([]);
  const [selectedKpiId, setSelectedKpiId] = useState([] as any)
  const [selectedCompany, setSelectedCompany] = useState([] as any)
  const [selectedCustomer, setSelectedCustomer] = useState([] as any)
  const [arrChartData, setArrChartData] = useState([] as any);//hold chartdatas for creating chart

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const [imageKey, setImageKey] = useState("")
  const [showCopyUrl, setShowCopyUrl] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    let data = location?.state?.dataObject;
    setStartDate(data?.start_date)
    setEndDate(data?.end_date)
    setSelectedCompany(location?.state?.company || [])
    setSelectedCustomer(location?.state?.customer || [])
    let selectedSubKpis = location.state.selectedSubKpis
    console.log('>>> selectedSubKpis: ', selectedSubKpis)
    setSelectedSubKpis(selectedSubKpis)
    setLoading(true)
    postDashboard(data).then((resp: any) => {
      console.log('postDashboard: ', resp);
      console.log('state: ', data)
      let resp_data = resp?.data
      // let arrResponse:any = []
      let arrResponsebyKpi: any = []
      selectedSubKpis.forEach((item: any) => {
        let _respFilteredData = resp_data?.filter((respData: any) => respData?.kpi_details?.id === item?.kpi?.id) || []
        console.log('_respFilteredData>>>', _respFilteredData)
        let _processData = processReportData({
          reportData: _respFilteredData,
          selectedKpi: item.kpi,
          selectedSubKpis: item.subKpi,
          selectedCustomerIds: data.customer,
          selectedCompanyIds: data.company,
          userType: getUserData()?.user_type,
        });

        let obj = {
          kpi: item.kpi,
          processData: _processData
        }

        arrResponsebyKpi.push(obj)

        // if(_processData && _processData.length>0){
        //     arrResponsebyKpi.push(_processData)
        // }

      });
      console.log('arrResponseData:', arrResponsebyKpi)
      if (arrResponsebyKpi.length > 0) {
        setArrResponseData(arrResponsebyKpi)
      }
      setLoading(false)
    })
  }, [])

  const arrOption: any = [];// hold options for each chart
  // Generate a random color for each dataset
  function getRandomColor() {
    const letters = "0123456789ABCDEF"
    let color = "#"
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }
  useEffect(() => {
    if (arrResponseData.length > 0) {
      let _arrChartData: any = [];
      selectedSubKpis.forEach((selectedSubKpi: any, index: any) => {
        let chartData: any = {
          datasets: []
        };
        chartData.labels = getMonthsInPeriod(startDate, endDate, "MMM YY");
        // Extract sub-KPIs
        const selectedKpi = selectedSubKpi.kpi;
        console.log('++++ selectedKpi: ', selectedKpi)
        if (arrResponseData.length >= (index + 1) && arrResponseData[index]) {
          let responseData = arrResponseData[index].processData;
          const subKPIs = responseData?.reduce((subKPIs: any, entry: any) => {
            const subKPI = entry.sub_kpi_details
            const subKPIId = subKPI.id
            const [showCompany, showCustomer] = checkShowCompanyAndShowCustomer(
              selectedKpi, subKPI, getUserData()?.user_type,
            )
            const companyId = showCompany && entry?.company ? '-' + entry?.company : 'cy';
            const customerId = showCustomer && entry?.customer ? '-' + entry?.customer : 'cx';
            const subKPIKey = `${subKPIId}${companyId}${customerId}`;
            const company: any = companyId && selectedCompany?.find((comp: any) => entry?.company === comp?.id);
            const customer: any = customerId && selectedCustomer?.find((cust: any) => entry?.customer === cust?.id);
            const companyName = company?.name ? ' > ' + company.name : '';//' > cy'
            const customerName = customer?.name ? ' > ' + customer.name : '';//' > cx'
            const subKPIName = `${subKPI.name}${companyName}${customerName}`
            if (!subKPIs[subKPIKey]) {
              subKPIs[subKPIKey] = {
                id: subKPIId,
                customer: customer,
                company: company,
                name: subKPIName,
                data: []
              }
            }

            subKPIs[subKPIKey].data.push({
              date: entry.date,
              fill_rate: getFillRate(subKPI, entry)
            })

            return subKPIs
          }, {})
          // Convert sub-KPIs object into an array
          const subKPIsArray = Object.values(subKPIs)
          // Sort the data points by date for each sub-KPI
          subKPIsArray?.forEach((subKPI: any) => {
            subKPI?.data.sort((a: any, b: any) => {
              const dateA: any = a ? moment(a.date).format('x') : null
              const dateB: any = b ? moment(b.date).format('x') : null
              if (dateA && dateB) {
                return dateA - dateB
              }
              // Handle cases where either a.date or b.date is undefined or null
              return 0
            })
          })
          // Iterate over each sub-KPI and populate the chartData object
          let companyWithData: any = []
          subKPIsArray?.forEach((subKPI: any, index) => {
            const dataPoints = subKPI?.data?.map((dataPoint: any) => {
              const monthYear = moment(dataPoint?.date).format("MMM YY")
              return {
                x: monthYear,
                y: dataPoint?.fill_rate
              }
            })

            const dataset: any = {
              label: subKPI?.name,
              data: dataPoints,
              borderColor: getRandomColor()
              // fill: false,
            }
            companyWithData?.push(subKPI?.company)
            chartData.datasets.push(dataset)
          });
          //  if(chartData?.datasets.length > 0){
          chartData.kpiName = selectedKpi?.performance_indicator || '';
          chartData.dateRange = getDateRange(startDate, endDate)

          //---- chart options start
          let options: any = {
            plugins: {
              legend: {
                display: true,
                labels: {
                  boxHeight: 2
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true,
                title: {
                  display: (selectedSubKpi?.subKpi[0]?.data_type === DATA_TYPE_DAYS
                    || selectedSubKpi?.subKpi[0]?.data_type === DATA_TYPE_RECORDS),
                  text: getSubKpiDataType(selectedSubKpi?.subKpi[0]?.data_type)
                },
                ticks: {
                  callback: (value: any) => {
                    return selectedSubKpi?.subKpi[0]?.data_type === DATA_TYPE_PERCENTAGE ? value + "%" : value + "..";
                  }
                }
              }
            }
          };
          //----- chart options end
          chartData.options = options
          //sub kpi with no data start 
          let selectedSubKpiIds = selectedSubKpi?.subKpi;
          let subKpiNamesWithoutData = []
          if (responseData.length) {
            const dataSubKPIIds = responseData.map((data: any) => data.sub_kpi_details.id)
            const subKpiIdsWithoutData: any = selectedSubKpiIds.filter(
              (subKPIId: any) => !dataSubKPIIds.includes(subKPIId)
            )
            const matchedNames: any = selectedSubKpiIds
              .filter((entry: any) => subKpiIdsWithoutData.includes(entry?.id))
              .map((entry: any) => entry.name)
            subKpiNamesWithoutData = matchedNames
          } else {
            subKpiNamesWithoutData = selectedSubKpiIds.map((entry: any) => entry.name);
          }
          chartData.subKpiNamesWithoutData = subKpiNamesWithoutData
          // subkpi with no data end
          //company with no data start
          console.log("Final selection companyWithData:", companyWithData)
          console.log("Final selection selectedCompany:", selectedCompany)
          let arrCompanyWithoutDataName: any = []
          if (selectedCompany && selectedCompany.length > 0) {
            selectedCompany?.forEach((selected_comp: any) => {
              let companyIndex = companyWithData?.findIndex((comp_data: any) => comp_data?.id === selected_comp?.id)
              if (companyIndex === -1) {
                arrCompanyWithoutDataName.push(selected_comp?.name)
              }
            });
          }
          console.log('arrCompanyWithoutDataName: ', arrCompanyWithoutDataName)
          chartData.companyNameWithoutData = arrCompanyWithoutDataName;
          //company with no data end
          // chartData.datasets = []
          // chartData.subKpiNamesWithoutData = ['hu','ha']
          let isDataAvailable = false;
          if (chartData?.datasets && chartData.datasets.length > 0) {
            chartData.datasets.forEach((dataset: any) => {
              if (dataset?.data && dataset?.data.length > 0) {
                dataset?.data.forEach((dataItem: any) => {
                  if (dataItem.y) {
                    isDataAvailable = true
                  }
                })
              }
            })
          }

          if (isDataAvailable) {
            _arrChartData.push(chartData)
          } else {
            chartData.datasets = []
            chartData.subKpiNamesWithoutData = selectedSubKpiIds.map((entry: any) => entry.name);
            _arrChartData.push(chartData)
          }

        }

      })
      if (_arrChartData.length > 0) {
        console.log('Final chart data: ', _arrChartData)
        setArrChartData(_arrChartData)

      }
    }
  }, [arrResponseData])
  const getDateRange = (_startDate: any, _endDate: any) => {
    return `${moment(_startDate).format("MMM YY")} - ${moment(_endDate).format("MMM YY")}`
  }
  const handleCaptureAndUpload = () => {
    captureRef &&
      captureRef.current &&
      html2canvas(captureRef?.current).then(canvas => {
        canvas.toBlob((blob: any) => {
          const formData = new FormData()
          formData.append("image", blob, "screenshot.png")
          uploadChartImage(formData).then((resp: any) => {
            //console.log(resp)
            setImageKey(resp?.key)
            setShowCopyUrl(true)
          })

        })
      })
  }
  const handleCopyUrl = () => {
    const text = `${APPCONFIG.axiosBaseUrl}/image/${imageKey}`
    navigator.clipboard
      .writeText(text)
      .then(() => {
        //console.log("Text copied to clipboard:", text)
        toast.success("url copied")
      })
      .catch(error => {
        console.error("Error copying text to clipboard:", error)
      })
  }

  return (
    <>
    <Loader open={loading} />
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        px: 2,
        ml: 30,
        mt: 15,
        mb: 2,
        backgroundColor: "#F8F9FC"
      }}
    >
      <Grid xs={12} container display={"flex"} flexDirection={"row"} sx={{ mt: 2 }}>
        <Grid item xs={9} display={"flex"} justifyItems={"left"} justifyContent={"flex-start"}>
          <h2>View Dashboard</h2>
        </Grid>
        {!showCopyUrl && (
          <Grid
            item
            xs={2}
            display={"flex"}
            justifyContent={"flex-end"}
            sx={{ mt: 2, ml: 6 }}
            justifyItems={"right"}
          >
            {arrChartData?.length ? (
              <ShareIcon onClick={handleCaptureAndUpload} style={{ cursor: 'pointer' }} />
            ) : null}
          </Grid>
        )}


        {/* <div ref={captureRef}> */}
        <Grid ref={captureRef} xs={12} container display={"flex"} flexDirection={"row"} >
          {showCopyUrl ? (
            <Grid
              item
              xs={12}
              display={"flex"}
              flexDirection={"row"}
              sx={{ m: 2 }}
            >
              <Grid
                item
                xs={6}
                display={"flex"}
                justifyContent={"center"}
              ></Grid>
              <Grid
                item
                xs={5}
                display={"flex"}
                justifyContent={"flex-center"}
                sx={{ mr: 1 }}
              >
                <Button
                  variant="contained"
                  style={{ background: "#F8F9FC", color: "#000000" }}
                  onClick={() => {
                    setShowCopyUrl(!showCopyUrl)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleCopyUrl}
                  variant="contained"
                  sx={{ ml: 2 }}
                  style={{ background: "#000099" }}
                >
                  Copy Url
                </Button>
              </Grid>
            </Grid>
          ) : null}
          {arrChartData && arrChartData.length > 0 && arrChartData.map((chartsData: any) => {
            return (
              <>
                <Grid xs={6} container display={"flex"} flexDirection={"row"} sx={{ mt: 2, p: 1 }}>
                  <Grid xs={12} container display={"flex"} flexDirection={"row"} sx={{ mt: 2 }}>
                    <Grid item xs={6} display={"flex"} justifyContent={"flex-start"}>
                      <h5>{chartsData.kpiName}</h5>
                    </Grid>
                    <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
                      <h5>{chartsData.dateRange}</h5>
                    </Grid>
                  </Grid>
                  <Grid xs={12} container display={"flex"} flexDirection={"row"}>
                    <Grid item xs={12} display={"flex"} justifyContent={"flex-start"}>
                      {chartsData &&
                        chartsData?.datasets &&
                        chartsData?.datasets?.length ? (
                        <Line data={chartsData} options={chartsData.options} />
                      ) : null}
                    </Grid>
                    {
                      chartsData &&
                        chartsData?.companyNameWithoutData &&
                        chartsData?.companyNameWithoutData.length > 0 &&
                        chartsData?.datasets &&
                        chartsData?.datasets?.length ? (
                        <Grid item xs={12} sx={{ m: 2, color: "#FF7A00" }}>
                          {`Note: Please note that `}
                          <strong>{chartsData?.companyNameWithoutData?.join(", ")}</strong>
                          {` currently has no available data.`}
                        </Grid>
                      ) : null
                    }
                    {chartsData?.subKpiNamesWithoutData?.length ? (
                      <Grid item xs={12} sx={{ m: 2, color: "#FF7A00" }}>
                        {`Note: Please note that the selected KPI (`}
                        <strong>{chartsData?.subKpiNamesWithoutData?.join(", ")}</strong>
                        {`) currently has no available data.`}
                      </Grid>

                    ) : null}

                  </Grid>
                </Grid>
              </>
            )
          })}
          {/* </div> */}
        </Grid>
      </Grid>
    </Box>
    </>
  );
}

export default ViewDashboard;

import { del, get, patch, post, put } from "./http-methods"

export const API = {
  sendEmailOtp: (payload: any) => post("/api/v1/send-email-otp/", payload),
  confirmEmailOtp: (payload: any) =>
    post("/api/v1/confirm-email-otp/", payload),
  getRegions: () => get("/api/v1/region/"),
  register: (payload: any) => post("/api/v1/signup/", payload),
  login: (payload: any) => post("/api/v1/login/", payload),
  logout: () => post("/api/v1/logout/"),
  resetSendEmailOtp: (payload: any) =>
    post("/api/v1/reset-password/send-email-otp/", payload),
  resetConfirmEmailOtp: (payload: any) =>
    post("/api/v1/reset-password/confirm-email-otp/", payload),
  resetUserPassword: (payload: any) => post("/api/v1/reset-password/", payload),
  getSubKpiList: () => get("/kpi/api/v1/sub-kpi/"),
  addKpi: (payload: any) => post("/kpi/api/v1/kpi/", payload),
  editKpi: (id: any, payload: any) => patch(`/kpi/api/v1/kpi/${id}/`, payload),
  getKpi: (id: any) => get(`/kpi/api/v1/kpi/${id}/`),
  getKpiList: (payload: any) =>
    get(
      `/kpi/api/v1/kpi/${payload?.category ? "?category=" + payload?.category : ""
      }`
    ),
  delKpi: (id: any) => del(`/kpi/api/v1/kpi/${id}/`),
  getCompanyList: () => get(`/api/v1/company/`),
  fetchReportData: (payload: any) => post("/kpi/api/v1/report-data/", payload),
  addOrEditReportData: (payload: any) =>
    post("/kpi/api/v1/add-report-data/", payload),
  getCustomerList: () => get(`/api/v1/customer/`),
  fetchChartData: (payload: any) => post("/kpi/api/v1/chart/", payload),
  getScorecard: (payload: any) =>
    get(
      `/kpi/api/v1/scorecard/?start_date=${payload?.start_date}&mid_date=${payload?.middle_date}&end_date=${payload?.end_date}&distributors=${payload?.distributors}&filter=${payload?.category}`
    ),
  getUsers: () => get("/api/v1/user/"),
  editUser: (id: any, payload: any) => put(`/api/v1/user/${id}/`, payload),
  delUser: (id: any) => del(`/api/v1/user/${id}/`),
  getFeedbackList: () => get("/feedback/api/v1/feedback/"),
  postFeedback: (payload: any) => post("/feedback/api/v1/feedback/", payload),
  replyFeedback: (payload: any) =>
    post("/feedback/api/v1/feedback-reply/", payload),
  uploadChartImage: (payload: any) =>
    post("/kpi/api/v1/upload-chart-image/", payload),
  getContactUs: () => get("/feedback/api/v1/contact-us/"),
  postDashboard: (payload: any) =>
    post("/kpi/api/v1/dashboard/", payload),
}

export const categories = [
  { id: 1, label: "SAFETY" },
  { id: 2, label: "LEGAL" },
  { id: 3, label: "QUALITY" },
  { id: 4, label: "DELIVERY" },
  { id: 5, label: "COST" }
]

export const priorities = [
  { id: 1, label: "GLOBAL" },
  { id: 2, label: "REGIONAL" },
  { id: 3, label: "LOCAL" }
]

export const statuses = [
  { id: 1, label: "Draft" },
  { id: 2, label: "Approved" }
]

export const permissions = [
  { id: 1, label: "OEM_EDITABLE" },
  { id: 2, label: "COMPANY_EDITABLE" }
]

export const financialYear = [
  { id: 1, label: "2021-22" },
  { id: 2, label: "2022-23" },
  { id: 3, label: "2023-24" },
  { id: 4, label: "2024-25" }
]

export const subKpiType = [
  { id: 1, label: "Overall" },
  { id: 2, label: "Company Specific" },
  { id: 3, label: "Customer Specific" }
]

export const usertype = [
  { id: 1, label: "OEM_ADMIN" },
  { id: 2, label: "OEM_STAFF" },
  { id: 3, label: "COMPANY_ADMIN" },
  { id: 4, label: "COMPANY_STAFF" }
]

export const DATA_TYPE_PERCENTAGE = 1
export const DATA_TYPE_RECORDS = 2
export const DATA_TYPE_DAYS = 3
export const DATA_TYPE_DECIMAL = 4

export const subKPIDataType = [
  { id: DATA_TYPE_PERCENTAGE, label: "Percentage", symbol: '%' },
  { id: DATA_TYPE_RECORDS, label: "Records", symbol: '#' },
  { id: DATA_TYPE_DAYS, label: "Days", symbol: 'Days' },
  { id: DATA_TYPE_DECIMAL, label: "Decimal", symbol: 'Decimal' }
]

import { Grid } from "@mui/material"
import { useEffect, useState } from "react"
import CreateFeedback from "./createFeedback"
import FeedbackList from "./feedbackList"

const UserFeedback = () => {
  const userTypesWithUsersTab = [2, 3, 4]
  const [userType, setUserType] = useState(null)

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const userDataString = localStorage.getItem("user") as string;
      const userData = JSON.parse(userDataString)
      setUserType(userData.user_type)
    }
  }, [])

  return (
    <>
      <Grid container display={"flex"} flexDirection={"row"} sx={{ mt: 2 }}>
        {userType && userTypesWithUsersTab.includes(userType) ? (
          <CreateFeedback />
        ) : (
          <FeedbackList />
        )}
      </Grid>
    </>
  )
}

export default UserFeedback

import Button from "@mui/material/Button"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Typography from "@mui/material/Typography"
import { Divider, Grid } from "@mui/material"

export default function DeletePopUp(props: any) {
  const { handleCloseDeletePopUp, handleDelete, kpiName, kpiId } = props

  return (
    <div>
      <Dialog
        onClose={handleCloseDeletePopUp}
        open={true}
        PaperProps={{
          sx: {
            width: 314,
            maxHeight: 261,
            borderRadius: "10px"
          }
        }}
      >
        <DialogTitle
          style={{ display: "flex", fontSize: "1rem" }}
          alignSelf={"center"}
          fontWeight={600}
        >
          Delete KPI
        </DialogTitle>
        <DialogContent>
          <Divider style={{ width: "100%" }} sx={{ mb: 2 }}></Divider>
          <Typography style={{ fontSize: 14, textAlign: "center" }}>
            {`Are you sure you want to permanently delete ${kpiName} KPI ?`}
          </Typography>
        </DialogContent>
        <DialogActions style={{ display: "flex", alignSelf: "center" }}>
          <Grid container display={"flex"} direction={"column"}>
            <Grid item xs={12}>
              <Button
                onClick={() => handleDelete(kpiId)}
                variant="contained"
                sx={{ m: 2 }}
                style={{
                  background: "#000099",
                  borderRadius: "5px",
                  width: "219px",
                  height: "38px",
                  fontSize: "14px"
                }}
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: 2, fontSize: 14, cursor: "pointer" }}
                textAlign={"center"}
                onClick={handleCloseDeletePopUp}
              >
                Cancel
              </Typography>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

import React, { useState, useEffect } from "react"
import { Line } from "react-chartjs-2"
import annotationPlugin from "chartjs-plugin-annotation"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement
} from "chart.js"

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
  PointElement
)

const SparkLineChart = (props: any) => {
  const { data, target, targetMonth, months } = props
  const [chartData, setChartData] = useState({ datasets: [] })
  const [options, setOptions] = useState({})

  useEffect(() => {
    setOptions({
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false,
          text: data?.[targetMonth] || "",
          padding: {
            top: "0"
          }
        },
        tooltip: {
          bodySpacing: 1,
          titleSpacing: 1,
          titleMarginBottom: 3,
          titleFont: {
            size: 6
          },
          bodyFont: {
            size: 6
          }
        },
        annotation: {
          annotations: {
            line: {
              type: "line",
              yMin: target || 0,
              yMax: target || 0,
              borderColor: "#0000003B",
              borderWidth: 2,
              display: target ? true : false
            }
          }
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          display: false
        },
        x: {
          display: false
        }
      }
    })
  }, [data, target, targetMonth])

  useEffect(() => {
    if (data) {
      const chartDataSets: any = { datasets: [] }
      const dataPoints = months?.map((month: any) => {
        const value = data?.find((monthData: any) => monthData[month])?.[month]
        return {
          x: month,
          y: value
        }
      })
      if (dataPoints?.length && dataPoints?.some((point: any) => point.y)) {
        chartDataSets.datasets.push({
          data: dataPoints,
          borderColor: "#140A9A80",
          spanGaps: true,
        })
      }

      setChartData(chartDataSets)
    }
  }, [data, target, months])

  return chartData?.datasets?.length > 0 ? (
    <Line data={chartData} options={options} />
  ) : (
    <>No Data</>
  )
}

export default SparkLineChart

import { useEffect, useState } from "react"
import {
  Box,
  Button,
  Grid,
  SelectChangeEvent,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  IconButton,
  Autocomplete
} from "@mui/material"
// import "react-datepicker/dist/react-datepicker.css"
import { useAuthContext } from "../../context/AuthContext"
import { ReactComponent as InfoIcon } from "../../assests/images/info.svg"
import { ReactComponent as TickIcon } from "../../assests/images/tick.svg"
import { useNavigate } from "react-router-dom"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { checkShowCompanyAndShowCustomer, getSubKpiColor, getSubKpiDataType, getSubKpiType, getUserData, isCompanyUser } from "../../utils/util"
import './style.css'
import { categories } from "../../api"

const CreateDashboard = () => {
  const { getKpiList, getCompanyList, getCustomerList } =
    useAuthContext()
  const userData = getUserData()
  const userType = userData?.user_type;
  const userCompany = JSON.parse(localStorage.getItem("company") as string);

  const nav = useNavigate()
  const [kpiList, setKpiList] = useState([] as any)
  const [companyList, setCompanyList] = useState([] as any)
  const [customerList, setCustomerList] = useState([])
  const [company, setCompany] = useState([])
  const [customer, setCustomer] = useState([])

  const [showSelectCompany, setShowSelectCompany] = useState(false)
  const [showSelectCustomer, setShowSelectCustomer] = useState(false)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [kpi, setKpi] = useState([] as any)
  // const [selectedKpi, setSelectedKpi] = useState({} as any)
  const [targettedSubKpi, setTargettedSubKpi] = useState([] as any)
  const [category, setCategory] = useState("");
  const [selectedKpi, setSelectedKpi] = useState([] as any)
  const [_subKpi, set_SubKpi] = useState([] as any)
  const [selectedSubKpis, setSelectedSubKpis] = useState([] as any)
  const [errorMsg, setErrorMsg] = useState("")



  useEffect(() => {
    setSelectedKpi([])
    setSelectedSubKpis([])
    setShowSelectCompany(false)
    setShowSelectCustomer(false)
    setCompany([])
    setCustomer([])
    setKpi([])
    getKpiList({ category }).then((resp: any) => {
      const approvedKpis = resp?.filter((item: any) => item.status === 2)
      setKpiList(approvedKpis)
    })
  }, [category])

  // useEffect(() => {
  //   const selectedKpiItem =
  //     kpiList && kpiList.filter((item: any) => item.id === kpi)
  //   setSelectedKpi(selectedKpiItem[0])
  // }, [kpi])
  useEffect(() => {
    console.log('Selected KPI: ', kpi)
    console.log('KPI details: ', kpiList)
    let arr: any = [];
    kpi?.forEach((element: any) => {
      const selectedKpiItem = kpiList && kpiList.filter((item: any) => item.id === element)
      console.log('selectedKpiItem: ', selectedKpiItem)
      if (selectedKpiItem.length > 0) {
        let obj = { kpi: selectedKpiItem[0], sub_kpi_details: selectedKpiItem[0].sub_kpi_details }
        arr.push(obj)
      }

    });
    if (selectedSubKpis?.length > 0) {
      //remove items from selectedSubKpis whose kpi are not selected
      let _selectedSubKpis: any = [];
      kpi.forEach((kpi_item: any) => {
        let index = selectedSubKpis.findIndex((item: any) => item.kpi.id === kpi_item)
        if (index > -1) {
          _selectedSubKpis.push(selectedSubKpis[index])
        }
      });
      setSelectedSubKpis(_selectedSubKpis)
    }

    console.log('setSelectedKpiSubKpi : ', arr)
    set_SubKpi([0])
    setSelectedKpi(arr)
  }, [kpi])

  useEffect(() => {
    getCompanyList().then((resp: any) => {
      setCompanyList(resp);
      console.log('getCompanyList: ', resp)
    })
    getCustomerList().then((resp: any) => {
      setCustomerList(resp)
      console.log('getCustomerList: ', resp)
    })
  }, [])

  // useEffect(() => {
  //   selectedSubKpis?.length &&
  //     selectedSubKpis.map((subKpi: any) => {
  //       const [ showCompany, showCustomer] = checkShowCompanyAndShowCustomer(
  //         selectedKpi,
  //         subKpi,
  //         userType
  //       );

  //       showCompany && setShowSelectCompany(showCompany);
  //       showCustomer && setShowSelectCustomer(showCustomer);
  //     })
  // }, [userType, selectedKpi, selectedSubKpis])
  useEffect(() => {
    console.log('>>> userType: ', userType)
    console.log('>>> kpi: ', kpi)
    console.log('>>> selectedSubKpis: ', selectedSubKpis)
    let arrShowCompany: any = []
    let arrShowCustomer: any = []
    if (selectedSubKpis && selectedSubKpis.length > 0) {
      selectedSubKpis.map((item: any) => {
        let _selectedKpi = item.kpi;
        let _subKpi = item.subKpi;
        if (_subKpi.length > 0) {
          _subKpi.map((subKpiItem: any) => {
            let [showCompany, showCustomer] = checkShowCompanyAndShowCustomer(
              _selectedKpi,
              subKpiItem,
              userType
            );
            arrShowCompany.push(showCompany)
            arrShowCustomer.push(showCustomer)
          })
        }

      })

    }
    console.log('>>> arrShowCompany:', arrShowCompany)
    if (arrShowCompany.length > 0) {
      let findShowCompanyIndex = arrShowCompany.findIndex((item: any) => item === true)
      if (findShowCompanyIndex > -1) {
        setShowSelectCompany(true)
      } else {
        setShowSelectCompany(false)
      }
    } else {
      setShowSelectCompany(false)
    }
    console.log('>>> arrShowCustomer:', arrShowCustomer)
    if (arrShowCustomer.length > 0) {
      let findShowCustomerIndex = arrShowCustomer.findIndex((item: any) => item === true)
      if (findShowCustomerIndex > -1) {
        setShowSelectCustomer(true)
      } else {
        setShowSelectCustomer(false)
      }
    } else {
      setShowSelectCustomer(false)
    }

  }, [userType, kpi, selectedSubKpis, _subKpi])

  const handleCompanyChange = (event: any, value: any) => {
    setCompany(value)
  }

  const handleCustomerChange = (event: any, value: any) => {
    setCustomer(value)
  }
  const calculateMonthDiff = (_startDate: any, _endDate: any) => {
    if (_startDate && _endDate) {
      let monthsDiff = _endDate.diff(_startDate, 'months')
      return monthsDiff
    } else {
      return -1;
    }

  }
  const handleStartDateChange = (value: any) => {
    setErrorMsg("")
    setStartDate(value);
    let monthsDiff = calculateMonthDiff(value, endDate)
    if (!(monthsDiff > 0 && monthsDiff <= 24)) {
      setErrorMsg("Please select the date range of less than 2 years")
    }
  };

  const handleEndDateChange = (value: any) => {
    setErrorMsg("")
    setEndDate(value);
    let monthsDiff = calculateMonthDiff(startDate, value)
    console.log("Date difference in months:", monthsDiff)
    if (!(monthsDiff > 0 && monthsDiff <= 24)) {
      setErrorMsg("Please select the date range of less than 2 years")
    }

  };

  const handleKpi = (event: SelectChangeEvent) => {
    let value = event.target.value
    setKpi(typeof value === 'string' ? value.split(',') : value)
  }

  const handleSelectSubKpi = (subKpi: any, kpi: any) => {
    console.log('handleSelectSubKpi subKpi:', subKpi)
    console.log('handleSelectSubKpi kpi:', kpi)
    let arrSubKpis = selectedSubKpis;
    if (arrSubKpis.length === 0) {
      let obj = {
        kpi: kpi,
        subKpi: [subKpi]
      }
      arrSubKpis.push(obj)
      setSelectedSubKpis(arrSubKpis)
    } else {
      let kpiIndex = arrSubKpis.findIndex((item: any) => item.kpi === kpi)
      if (kpiIndex > -1) {
        let arrSubKpi = arrSubKpis[kpiIndex].subKpi;
        let subKpiIndex = arrSubKpi.findIndex((item: any) => item.id === subKpi.id)
        if (subKpiIndex > -1) {
          //remove it
          arrSubKpi.splice(subKpiIndex, 1)
        } else {
          //add it
          arrSubKpi.push(subKpi)
        }
        setSelectedSubKpis(arrSubKpis)
      } else {
        let obj = {
          kpi: kpi,
          subKpi: [subKpi]
        }
        arrSubKpis.push(obj)
        setSelectedSubKpis(arrSubKpis)
      }

    }
    console.log('handleSelectSubKpi arrSubKpis:: ', arrSubKpis)
    set_SubKpi([..._subKpi, subKpi.id])

  }

  const isSubKpiSelected = (subKpiId: any, kpiId: any) => {
    if (selectedSubKpis.length > 0) {
      let kpiIndex = selectedSubKpis.findIndex((item: any) => item.kpi === kpiId)
      let arrSubKpi = selectedSubKpis[kpiIndex]?.subKpi || [];
      let subKpiIndex = arrSubKpi?.findIndex((item: any) => item.id === subKpiId)
      if (subKpiIndex > -1) {
        return true
      } else {
        return false
      }
    } else {
      return false;
    }
  }

  const getSelectedKpiDataType = (kpiId: any) => {
    let dataType = -1;
    if (selectedSubKpis.length > 0) {
      let kpiIndex = selectedSubKpis.findIndex((item: any) => item.kpi === kpiId)
      let arrSubKpi = selectedSubKpis[kpiIndex]?.subKpi || [];
      if (arrSubKpi.length > 0) {
        dataType = arrSubKpi[0].data_type
      }
    }
    return dataType;
  }

  useEffect(() => {
    console.log('setSelectedKpi: ', setSelectedKpi)
  }, [_subKpi])

  const handleTarget = (event: any, value: any) => {
    setTargettedSubKpi(value)
  }

  const handleCategory = (event: SelectChangeEvent) => {
    setCategory(event.target.value)
  }

  const handleReset = () => {
    setCategory("")
    setSelectedKpi([])
    setSelectedSubKpis([])
    setShowSelectCompany(false)
    setShowSelectCustomer(false)
    setCompany([])
    setCustomer([])
    setKpi([])
    setStartDate("")
    setEndDate("")
  }

  const handleNextBtn = () => {
    const startDateObject = new Date(startDate);
    const startYear = startDateObject.getFullYear();
    const startMonth = String(startDateObject.getMonth() + 1).padStart(2, "0");
    const formattedStartDate = `${startYear}-${startMonth}-01`;

    const endDateObject = new Date(endDate);
    const endYear = endDateObject.getFullYear();
    const endMonth = String(endDateObject.getMonth() + 1).padStart(2, "0");
    const formattedEndDate = `${endYear}-${endMonth}-01`;

    console.log('Submit selectedSubKpis:', selectedSubKpis);
    console.log('Submit formattedStartDate:', formattedStartDate);
    console.log('Submit formattedEndDate:', formattedEndDate);
    console.log('Submit company:', company);
    console.log('Submit customer:', customer);

    let dataObject: any = {}

    if (selectedSubKpis.length > 0) {
      let arrKpiSubkpi: any = [];
      selectedSubKpis?.forEach((item: any) => {
        if (item?.subKpi.length > 0) {
          let obj: any = {};
          obj.kpi = item.kpi.id;
          let arrSubKpi: any = [];
          item?.subKpi.forEach((subkpi: any) => {
            arrSubKpi.push(subkpi.id)
          });
          obj.sub_kpi = arrSubKpi;
          arrKpiSubkpi.push(obj)
        }
      })
      if (arrKpiSubkpi.length > 0) {
        dataObject.kpi_sub_kpi = arrKpiSubkpi;
        dataObject.start_date = formattedStartDate;
        dataObject.end_date = formattedEndDate;
        dataObject.company = []
        if (company.length > 0) {
          company?.forEach((comp: any) => {
            dataObject.company.push(comp.id)
          })
        }else if(isCompanyUser(userType)) {
          const userCompanyInfo = isCompanyUser(userType) && companyList?.find(
            (item: any) => (item?.name === userCompany)
          );
          dataObject.company.push(userCompanyInfo?.id)
        } else {
          dataObject.company = null
        }

        dataObject.customer = [];
        if (customer.length > 0) {
          customer?.forEach((cust: any) => {
            dataObject.customer.push(cust.id)
          })
        } else {
          dataObject.customer = null;
        }
      }
    }

    if (dataObject.kpi_sub_kpi.length > 0) {
      console.log('Submit dataObject: ', dataObject)
      nav(`/view-dashboard`, {
        state: {
          dataObject,
          selectedSubKpis,
          company,
          customer
        }
      })
    }

    // nav(`/view-chart`, {
    //   state: {
    //     selectedKpi,
    //     kpiId: selectedKpi.id,
    //     startDate: formattedStartDate,
    //     endDate: formattedEndDate,
    //     selectedSubKpiIds: selectedSubKpis?.map((obj: any) => obj.id),
    //     company:  company,
    //     customer: customer,
    //     target: targettedSubKpi,
    //     selectedSubKpis: selectedSubKpis,
    //   }
    // })
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        px: 2,
        ml: 30,
        mt: 15,
        mb: 2,
        backgroundColor: "#F8F9FC"
      }}
    >
      <Grid container display={"flex"} flexDirection={"row"}>
        <Grid item xs={6} display={"flex"} justifyContent={"flex-start"}>
          <h2>Create Dashboard</h2>
        </Grid>
        <Grid container display={"flex"} flexDirection={"row"} >
          <Grid item xs={12} display={"flex"} justifyContent={"flex-start"}>
            <Typography className="boldLabel">
              Step 1:
            </Typography>
            <Typography>
              Select Category
            </Typography>

          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"flex-start"} sx={{ mt: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="category-select-label">Category</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={category}
                label="Select Category"
                size="small"
                style={{
                  marginBottom: 0,
                  borderColor: "#FFFFFF",
                  background: "#FFFFFF",
                  textAlign: 'left'
                }}
                onChange={handleCategory}
              >
                {categories &&
                  categories.map((category: any) => {
                    return (
                      <MenuItem value={category.id}>
                        {category.label}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container display={"flex"} flexDirection={"row"} sx={{ mt: 2 }}>
          <Grid item xs={12} display={"flex"} justifyContent={"flex-start"}>
            <Typography className="boldLabel">
              Step 2:
            </Typography>
            <Typography>
              Select KPI(s) and Sub KPI(s)
            </Typography>

          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"flex-start"} sx={{ mt: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="kpi-select-label">Select KPI</InputLabel>
              <Select
                labelId="kpi-select-label"
                id="kpi-select"
                value={kpi}
                label="Select KPI"
                size="small"
                style={{
                  marginBottom: 0,
                  borderColor: "#FFFFFF",
                  background: "#FFFFFF",
                  textAlign: 'left'
                }}
                onChange={handleKpi}
                multiple
              >
                {kpiList &&
                  kpiList.map((kpi: any) => {
                    return (
                      <MenuItem value={kpi.id}>
                        {kpi.performance_indicator}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </Grid>
          {_subKpi && selectedKpi && selectedKpi.length > 0 &&
            selectedKpi.map((item: any, index: any) => {
              return (

                <Grid container xs={12} sx={{ p: 2, border: '1px solid #AFAF9B', borderRadius: "3px", background: "#FFFFFF", mt: 2 }}>
                  {/* <Grid item key={`kpi_name_${index}`}> */}
                  <Typography className="subKpiName">Sub KPI(s) for {item.kpi.performance_indicator}</Typography>
                  {/* </Grid> */}
                  <Grid container spacing={2} alignItems="center" xs={12} key={`kpi_subKpi_${index}`}>
                    {item.sub_kpi_details.length > 0 && item.sub_kpi_details.map((subKpi: any, index: any) => {
                      const disabled = (getSelectedKpiDataType(item.kpi) > -1 && getSelectedKpiDataType(item.kpi) !== subKpi.data_type) ? true : false;
                      const type = getSubKpiType(subKpi.type)

                      return (
                        <Grid item xs={4} key={`subKpi_name_${index}`}>
                          <Typography
                            onClick={() => !disabled && handleSelectSubKpi(subKpi, item.kpi)}
                            style={{
                              borderRadius: 4,
                              //backgroundColor: getSubKpiColor(subKpi),
                              cursor: disabled ? "not-allowed" : "pointer",
                              opacity: disabled ? 0.5 : 1,
                              backgroundColor: (isSubKpiSelected(subKpi.id, item.kpi) ? "#000099" : getSubKpiColor(subKpi)),
                              color: isSubKpiSelected(subKpi.id, item.kpi)
                                ? "#FFFFFF"
                                : "#000000",
                            }}

                          >
                            {isSubKpiSelected(subKpi.id, item.kpi) ? (
                              <TickIcon />
                            ) : null}{" "}
                            {subKpi.name}
                            {
                              <Tooltip title={<>
                                {/* {disabled && (
                                        <>
                                          <div style={{color: "red", background: 'white' }}>
                                            <b>Selection disabled. You can select sub kPIs with same data type to generate chart</b>
                                          </div>
                                          <br />
                                        </>
                                      )} */}
                                <div>
                                  <b>Sub KPI Name: {subKpi.name}</b>
                                </div>

                                <div>
                                  Type: {type}
                                </div>
                                <br />
                                <div>
                                  Data Type: {getSubKpiDataType(subKpi.data_type)}
                                </div>
                                <br />
                                <div>
                                  Description: {subKpi.explanatory_text}
                                </div>
                              </>}>
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </Typography>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>

              )
            })
          }
          {selectedKpi && selectedKpi.length > 0 && (
            <Grid container spacing={1} xs={10} sx={{ mt: 2 }}>
              <Grid item xs={1} display={"flex"} justifyContent={"flex-end"}>
                <Box
                  sx={{
                    height: "15px",
                    width: "15px",
                    borderRadius: "50%",
                    backgroundColor: "#DDDDDD"
                  }}
                ></Box>
              </Grid>
              <Grid
                item
                xs={2}
                display={"flex"}
                justifyContent={"flex-start"}
              >
                <Typography variant="caption">Overall</Typography>
              </Grid>
              <Grid item xs={1} display={"flex"} justifyContent={"flex-end"}>
                <Box
                  sx={{
                    height: "15px",
                    width: "15px",
                    borderRadius: "50%",
                    backgroundColor: "#9BB9B9"
                  }}
                ></Box>
              </Grid>
              <Grid
                item
                xs={2}
                display={"flex"}
                justifyContent={"flex-start"}
              >
                <Typography variant="caption">Company Specific</Typography>
              </Grid>
              <Grid item xs={1} display={"flex"} justifyContent={"flex-end"}>
                <Box
                  sx={{
                    height: "15px",
                    width: "15px",
                    borderRadius: "50%",
                    backgroundColor: "#AFAF9B"
                  }}
                ></Box>
              </Grid>
              <Grid
                item
                xs={2}
                display={"flex"}
                justifyContent={"flex-start"}
              >
                <Typography variant="caption">Customer Specific</Typography>
              </Grid>
              <Grid item xs={1} display={"flex"} justifyContent={"flex-end"}>
                <Box
                  sx={{
                    height: "15px",
                    width: "15px",
                    borderRadius: "50%",
                    backgroundColor: "#000099"
                  }}
                ></Box>
              </Grid>
              <Grid
                item
                xs={2}
                display={"flex"}
                justifyContent={"flex-start"}
              >
                <Typography variant="caption">Selected</Typography>
              </Grid>
            </Grid>

          )}
        </Grid>
        {(showSelectCompany || showSelectCustomer) && (
          <Grid container display={"flex"} flexDirection={"row"} sx={{ mt: 2 }}>
            <Grid item xs={12} display={"flex"} justifyContent={"flex-start"}>
              <Typography className="boldLabel">
                Step 3:
              </Typography>
              <Typography>
                Select Distrbutor(s) and Customer(s)
              </Typography>

            </Grid>
            {showSelectCompany && (
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={companyList || []}
                  getOptionLabel={(option: any) => option?.name}
                  value={company || []}
                  onChange={handleCompanyChange}
                  filterSelectedOptions
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Select Company"
                      placeholder="Select Company"
                      style={{ background: "#FFFFFF" }}
                    />
                  )}
                />
              </Grid>
            )}
            {showSelectCustomer && (
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={customerList || []}
                  getOptionLabel={(option: any) => option?.name}
                  value={customer || []}
                  onChange={handleCustomerChange}
                  filterSelectedOptions
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Select Customer"
                      placeholder="Select Customer"
                      style={{ background: "#FFFFFF" }}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        )}
        <Grid item xs={12} sx={{ mt: 2 }} display={"flex"}>
          <Grid item xs={6} sx={{ pr: 1 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Box style={{ background: "#FFFFFF", width: '100%' }}>
                <DatePicker
                  label={"Select Start Month"}
                  value={startDate || null}
                  onChange={handleStartDateChange}
                  views={["month", "year"]}
                  sx={{ width: "100% " }}
                />
              </Box>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} style={{ background: "#FFFFFF" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label={"Select End Month"}
                value={endDate || null}
                onChange={handleEndDateChange}
                views={["month", "year"]}
                sx={{ width: "100% " }}
              />
            </LocalizationProvider>
          </Grid>


        </Grid>
        {errorMsg && errorMsg.length > 0 && (
          <Grid>
            <Typography display={"flex"} style={{
              textAlign: "left",
              color: "#FF0000",
              fontSize: '12px'
            }}>
              {errorMsg}
            </Typography>
          </Grid>
        )}
        <Grid container display={"flex"} sx={{ mt: 2, mb: 4 }}>
          <Grid
            item
            xs={6}
            sx={{ pr: 1 }}
            justifyContent={"flex-end"}
            display={"flex"}
          >
            <Button
              onClick={handleReset}
              variant="contained"
              style={{ background: "#F8F9FC", color: "#000000" }}
            >
              Reset
            </Button>
          </Grid>
          <Grid item xs={6} display={"flex"}>
            <Button
              onClick={handleNextBtn}
              variant="contained"
              style={{ background: "#000099" }}
              disabled={
                !selectedKpi ||
                !selectedSubKpis.length ||
                (showSelectCompany && !company.length) ||
                (showSelectCustomer && !customer.length) ||
                !startDate || !endDate || (errorMsg.length > 0)
              }
              classes={{ disabled: "custom-disabled-button" }}
            >
              Create Dashboard
            </Button>{" "}
          </Grid>
        </Grid>
      </Grid>

    </Box>
  );
}

export default CreateDashboard;
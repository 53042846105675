import { useState } from "react"
import { ReactComponent as AppLogo } from "../../assests/images/AppLogo.svg"
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  ThemeProvider,
  createTheme
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import { useAuthContext } from "../../context/AuthContext"
import "../SignIn/SignIn.css"

const VerifyEmail = () => {
  const nav = useNavigate()
  const { sendEmailOtp } = useAuthContext()
  const theme = createTheme({
    palette: {
      secondary: {
        main: "#000099"
      }
    }
  })

  const [email, setEmail] = useState("")
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleEmail = (event: any) => {
    setEmail(event.target.value)
  }

  const handleSendEmailOtp = async (event: any) => {
    event.preventDefault()
    // Validate email
    const validationErrors: { [key: string]: string } = {}
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (email.trim() === "") {
      validationErrors.email = "Email is required"
    } else if (!emailRegex.test(email)) {
      validationErrors.email = "Invalid email format"
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
    } else {
      sendEmailOtp({ email }).then(() =>
        nav("/verify-otp", {
          state: { email: email }
        })
      )
    }
  }

  const navigateToLogin = () => {
    nav("/login")
  }

  return (
    <>
      <div className="page-container">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            px: 2
          }}
        >
          <Paper
            sx={{
              p: 2,
              textAlign: "center",
              maxWidth: "400px",
              width: "100%"
            }}
            style={{
              borderColor: "#000099",
              borderWidth: 2,
              borderStyle: "solid",
              borderRadius: 8
            }}
          >
            <AppLogo />
            <Box className="sign-in-title">Register</Box>
            <div className="grid-container">
              <Grid
                container
                direction={"column"}
                justifyItems={"center"}
                alignItems={"center"}
              >
                <Grid item xs={12}>
                  <TextField
                    label="Email I'd"
                    className="sign-in-field"
                    variant="outlined"
                    size="small"
                    style={{ width: 320, marginBottom: 0 }}
                    value={email}
                    onChange={handleEmail}
                    margin="normal"
                    required
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>
              </Grid>
            </div>

            <Grid
              container
              spacing={2}
              direction={"column"}
              alignItems={"center"}
            >
              <br />
              <Grid item xs={12}>
                <ThemeProvider theme={theme}>
                  <Button
                    variant={"contained"}
                    color="secondary"
                    onClick={handleSendEmailOtp}
                    style={{
                      width: 320,
                      color: "#FFFFFF",
                      textTransform: "none"
                    }}
                  >
                    Continue
                  </Button>
                </ThemeProvider>
              </Grid>
            </Grid>
            <Grid container display={"flex"} justifyContent={"flex-start"}>
              <Grid item sx={{ ml: 5, mt: 2 }}>
                <span>Already have an Account?</span>{" "}
                <span
                  style={{
                    cursor: "pointer",
                    color: "#000099",
                    textDecoration: "underline"
                  }}
                  onClick={navigateToLogin}
                >
                  Login
                </span>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </div>
    </>
  )
}

export default VerifyEmail

import {
  Box,
  Button,
  Grid,
  SelectChangeEvent,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  IconButton
} from "@mui/material"
import { useAuthContext } from "../../context/AuthContext"
import { useEffect, useState } from "react"
import { categories, financialYear } from "../../api"
import { ReactComponent as InfoIcon } from "../../assests/images/info.svg"
import { ReactComponent as TickIcon } from "../../assests/images/tick.svg"
import { useNavigate } from "react-router-dom"
import { checkShowCompanyAndShowCustomer, getSubKpiColor, getSubKpiDataType, getSubKpiType, getUserData, isCompanyUser } from "../../utils/util"
import "./kpiReport.css"

const KpiReport = () => {
  const { getKpiList, getCompanyList, getCustomerList } = useAuthContext()
  const userData = getUserData()
  const userType = userData?.user_type;

  const nav = useNavigate()
  const [kpiList, setKpiList] = useState([] as any)
  const [companyList, setCompanyList] = useState([] as any)
  const [customerList, setCustomerList] = useState([])
  const [company, setCompany] = useState("")
  const [customer, setCustomer] = useState("")
  const [selectedSubKpis, setSelectedSubKpis] = useState([] as any)
  const [showSelectCompany, setShowSelectCompany] = useState(false)
  const [showSelectCustomer, setShowSelectCustomer] = useState(false)
  const [companyName, setCompanyName] = useState("")
  const [customerName, setCustomerName] = useState("")
  const [category, setCategory] = useState("");
  const userCompany = JSON.parse(localStorage.getItem("company") as string);

  const [kpi, setKpi] = useState("")
  const [year, setYear] = useState("")
  const [date, setDate] = useState({
    startDate: "",
    endDate: ""
  })
  const [selectedKpi, setSelectedKpi] = useState({} as any)
  const handleItemClick = (subKpi: any) => {
    if (selectedSubKpis.includes(subKpi)) {
      // to unselect the item
      setSelectedSubKpis(selectedSubKpis.filter((item: any) => item !== subKpi))
      setShowSelectCompany(false)
      setShowSelectCustomer(false)
      setCompany("")
      setCustomer("")
    } else {
      setSelectedSubKpis([...selectedSubKpis, subKpi])
    }
  }

  useEffect(() => {
    category && getKpiList({ category }).then((resp: any) => {
      const approvedKpis = resp?.filter((item: any) => item.status === 2)
      setKpiList(approvedKpis)
    })
  }, [category])

  useEffect(() => {
    const selectedKpiItem =
      kpiList && kpiList.filter((item: any) => item.id === kpi)
    setSelectedKpi(selectedKpiItem[0])
  }, [kpi])

  useEffect(() => {
    selectedKpi?.sub_kpi_details?.length && setSelectedSubKpis([...selectedKpi?.sub_kpi_details])
  }, [selectedKpi])

  useEffect(() => {
    getCompanyList().then((resp: any) => {
      setCompanyList(resp)
    })
    getCustomerList().then((resp: any) => {
      setCustomerList(resp)
    })
  }, [])

  useEffect(() => {
    if (Number(year) === 1) {
      setDate({ ...date, startDate: "2021-04-01", endDate: "2022-03-01" })
    } else if (Number(year) === 2) {
      setDate({ ...date, startDate: "2022-04-01", endDate: "2023-03-01" })
    } else if (Number(year) === 3) {
      setDate({ ...date, startDate: "2023-04-01", endDate: "2024-03-01" })
    } else if (Number(year) === 4)
      setDate({ ...date, startDate: "2024-04-01", endDate: "2025-03-01" })
  }, [year])

  useEffect(() => {
    selectedSubKpis?.length &&
      selectedSubKpis.map((subKpi: any) => {
        const [showCompany, showCustomer] = checkShowCompanyAndShowCustomer(
          selectedKpi,
          subKpi,
          userType
        );

        showCompany && setShowSelectCompany(showCompany);
        showCustomer && setShowSelectCustomer(showCustomer);
      })
  }, [userType, selectedKpi, selectedSubKpis])

  const handleCompany = (event: SelectChangeEvent) => {
    companyList?.map((company: any) => {
      if (company.id === event.target.value) {
        setCompanyName(company.name)
      }
    })
    setCompany(event.target.value)
  }

  const handleCustomer = (event: SelectChangeEvent) => {
    customerList?.map((customer: any) => {
      if (customer.id === event.target.value) {
        setCustomerName(customer.name)
      }
    })
    setCustomer(event.target.value)
  }

  const handleCategory = (event: SelectChangeEvent) => {
    setSelectedSubKpis([])
    setKpi("")
    setCompany("")
    setCustomer("")
    setShowSelectCompany(false)
    setShowSelectCustomer(false)
    setCategory(event.target.value)
  }

  const handleKpi = (event: SelectChangeEvent) => {
    setKpi(event.target.value)
  }

  const handleYear = (event: SelectChangeEvent) => {
    setYear(event.target.value)
  }

  const handleReset = () => {
    setCategory("")
    setSelectedSubKpis([])
    setKpi("")
    setYear("")
    setCompany("")
    setCustomer("")
    setShowSelectCompany(false)
    setShowSelectCustomer(false)
  }

  const handleNextBtn = () => {
    const userCompanyInfo = isCompanyUser(userType) && companyList?.find(
      (item: any) => (item?.name === userCompany)
    );
    nav(`/kpi-report/view/${selectedKpi.id}`, {
      state: {
        kpiId: selectedKpi.id,
        selectedKpi,
        date: date,
        selectedSubKpis: selectedSubKpis,
        company: isCompanyUser(userType) && userCompanyInfo ? userCompanyInfo?.id : company,
        customer: customer,
        year: year,
        companyName: isCompanyUser(userType) && userCompanyInfo ? userCompanyInfo?.name : companyName,
        customerName: customerName,
        kpiPermission: selectedKpi.permission
      }
    })
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 2,
          ml: 30,
          mr: 5,
          mt: 15,
          mb: 2,
          backgroundColor: "#F8F9FC"
        }}
      >
        <Grid container display={"flex"} flexDirection={"row"} sx={{ mt: 1 }}>
          <Grid item xs={3} display={"flex"} justifyContent={"flex-start"}>
            <h2>View KPI Report</h2>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="category-select-label">Select Category</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={category}
                label="Select Category"
                size="small"
                style={{
                  marginBottom: 0,
                  borderColor: "#FFFFFF",
                  background: "#FFFFFF"
                }}
                onChange={handleCategory}
              >
                {categories &&
                  categories.map((category: any) => {
                    return (
                      <MenuItem value={category.id}>
                        {category.label}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="kpi-select-label">Select KPI</InputLabel>
              <Select
                labelId="kpi-select-label"
                id="kpi-select"
                value={kpi}
                label="Select KPI"
                size="small"
                style={{
                  marginBottom: 0,
                  borderColor: "#FFFFFF",
                  background: "#FFFFFF"
                }}
                onChange={handleKpi}
              >
                {kpiList &&
                  kpiList.map((kpi: any) => {
                    return (
                      <MenuItem value={kpi.id}>
                        {kpi.performance_indicator}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={1} display={"flex"} alignItems={"flex-start"} sx={{ mt: 2 }}>
            <InputLabel>Sub KPI</InputLabel>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ p: 2, border: '1px solid #AFAF9B', borderRadius: "3px", background: "#FFFFFF" }}>
              <Typography>
                <Grid container spacing={2} alignItems="center">
                  {selectedKpi && selectedKpi?.sub_kpi_details?.length
                    ? selectedKpi?.sub_kpi_details?.map(
                      (subKpi: any, index: any) => {
                        const type = getSubKpiType(subKpi.type)
                        return (<Grid item xs={4} key={index}>
                          <Typography
                            onClick={() => handleItemClick(subKpi)}
                            sx={{ mt: 1, mb: 1 }}
                            style={{
                              cursor: "pointer",
                              borderRadius: 4,
                              color: selectedSubKpis.includes(subKpi)
                                ? "#FFFFFF"
                                : "#000000",
                              backgroundColor: selectedSubKpis.includes(
                                subKpi
                              )
                                ? "#140A9A"
                                : getSubKpiColor(subKpi)
                            }}
                          >
                            {selectedSubKpis.includes(subKpi) ? (
                              <TickIcon />
                            ) : null}{" "}
                            {subKpi.name}
                            {
                              <Tooltip
                                title={
                                  <>
                                    <div>
                                      <b>Sub KPI Name: {subKpi.name}</b>
                                    </div>
                                    <br />
                                    <div>
                                      Type: {type}
                                    </div>
                                    <br />
                                    <div>
                                      Data Type: {getSubKpiDataType(subKpi.data_type)}
                                    </div>
                                    <br />
                                    <div>
                                      Description: {subKpi.explanatory_text}
                                    </div>
                                  </>
                                }
                              >
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </Typography>
                        </Grid>
                        )
                      })
                    : null}
                </Grid>
              </Typography>
            </Box>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs="auto">
                  <Box
                    sx={{
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                      backgroundColor: "#DDDDDD"
                    }}
                  ></Box>
                </Grid>
                <Grid
                  item
                  xs="auto"
                >
                  <Typography variant="caption" mr={3}>Overall</Typography>
                </Grid>
                <Grid item xs="auto">
                  <Box
                    sx={{
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                      backgroundColor: "#9BB9B9"
                    }}
                  ></Box>
                </Grid>
                <Grid
                  item
                  xs="auto"
                >
                  <Typography variant="caption" mr={3}>Company Specific</Typography>
                </Grid>
                <Grid item xs="auto">
                  <Box
                    sx={{
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                      backgroundColor: "#AFAF9B"
                    }}
                  ></Box>
                </Grid>
                <Grid
                  item
                  xs="auto"
                >
                  <Typography variant="caption" mr={3}>Customer Specific</Typography>
                </Grid>
                <Grid item xs="auto">
                  <Box
                    sx={{
                      height: "15px",
                      width: "15px",
                      borderRadius: "50%",
                      backgroundColor: "#140A9A"
                    }}
                  ></Box>
                </Grid>
                <Grid
                  item
                  xs="auto"
                >
                  <Typography variant="caption">Selected</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {showSelectCompany ? (
            <Grid item xs={12} sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="kpi-select-label">Select Company</InputLabel>
                <Select
                  labelId="company-select-label"
                  id="company-select"
                  value={company}
                  label="Company"
                  size="small"
                  style={{
                    marginBottom: 0,
                    borderColor: "#FFFFFF",
                    background: "#FFFFFF"
                  }}
                  onChange={handleCompany}
                >
                  {companyList &&
                    companyList.map((company: any) => {
                      return (
                        <MenuItem value={company.id}>{company.name}</MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          {showSelectCustomer ? (
            <Grid item xs={12} sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="kpi-select-label">Select Customer</InputLabel>
                <Select
                  labelId="customer-select-label"
                  id="customer-select"
                  value={customer}
                  label="Customer"
                  size="small"
                  style={{
                    marginBottom: 0,
                    borderColor: "#FFFFFF",
                    background: "#FFFFFF"
                  }}
                  onChange={handleCustomer}
                >
                  {customerList &&
                    customerList.map((customer: any) => {
                      return (
                        <MenuItem value={customer.id}>{customer.name}</MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="year-select-label">Financial Year</InputLabel>
              <Select
                labelId="year-select-label"
                id="year-select"
                value={year}
                label="Financial Year"
                size="small"
                style={{
                  marginBottom: 0,
                  borderColor: "#FFFFFF",
                  background: "#FFFFFF"
                }}
                onChange={handleYear}
              >
                {financialYear &&
                  financialYear.map((year: any) => {
                    return <MenuItem value={year.id}>{year.label}</MenuItem>
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid container display={"flex"} sx={{ mt: 2, mb: 4 }}>
            <Grid
              item
              xs={6}
              sx={{ pr: 1 }}
              justifyContent={"flex-end"}
              display={"flex"}
            >
              <Button
                onClick={handleReset}
                variant="contained"
                style={{ background: "#F8F9FC", color: "#000000" }}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={6} display={"flex"}>
              <Button
                onClick={handleNextBtn}
                variant="contained"
                style={{ background: "#000099" }}
                disabled={
                  !selectedKpi ||
                  !selectedSubKpis.length ||
                  !year ||
                  (showSelectCompany && !company) ||
                  (showSelectCustomer && !customer)
                }
                classes={{ disabled: "custom-disabled-button" }}
              >
                View Report
              </Button>{" "}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default KpiReport

import React, { useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography'

function not(a: any, b: any) {
  return a.filter((value: any) => !b.some((item: any) => item?.id === value?.id));
}

function intersection(a: any, b: any) {
  return a.filter((value: any) => b.some((item: any) => item?.id === value?.id));
}

function union(a: any, b: any) {
  return [...a, ...not(b, a)];
}

export default function TransferList(props: any) {
  const {itemsList=[], selectionLimit=5, setSelectedList } = props;
  const [checked, setChecked] = useState<any[]>([]);
  const [left, setLeft] = useState<any[]>(itemsList);
  const [right, setRight] = useState<any[]>([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    setLeft(itemsList);
  }, [itemsList])

  const handleToggle = (value:any) => () => {
    const currentIndex = checked?.findIndex((item: any) => item?.id === value?.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items:any[]) =>
    intersection(checked, items).length;

  const handleCheckedRight = () => {
    const selected = right.concat(leftChecked);
    setRight(selected);
    setSelectedList(selected);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    const selected = not(right, rightChecked);
    setRight(selected);
    setSelectedList(selected);
    setChecked(not(checked, rightChecked));
  };

  const customList = (title: React.ReactNode, items: any[]) => (
    <>
      <Card sx={{border: '1px solid rgba(0, 0, 0, 0.23)', boxShadow: 'none'}}>
        <List
          sx={{
          width: '100%',
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
          }}
          dense
          component="div"
          role="list"
        >
        {items.map((value: any) => {
          const labelId = `transfer-list-all-item-${value?.id}-label`;
          const selected = checked?.some((item: any) => item?.id === value?.id);
          const disabled = Boolean(
            title === 'Choices' && !selected && union(checked, right)?.length >= selectionLimit
          );

          return (
            <ListItemButton
              key={value?.id}
              role="listitem"
              onClick={handleToggle(value)}
              selected={selected}
              disabled={disabled}
              dense={true}
            >
              <ListItemIcon>
                <Checkbox
                checked={selected}
                tabIndex={-1}
                disableRipple
                inputProps={{
                    'aria-labelledby': labelId,
                }}
                disabled={disabled}
              />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value?.name} />
            </ListItemButton>
          );
        })}
        </List>
      </Card>
      <Typography variant='caption' sx={{fontSize: '0.75rem', color: 'rgba(0, 0, 0, 0.5)'}}>
        {
          title === 'choices'
            ? `${items.length} Distributors found`
            :`${numberOfChecked(items)}/${items.length} Distributors selected`
        }
        </Typography>
    </>
  );

  return (
    <Grid item container spacing={2} alignItems="center">
      <Grid item xs={5}>{customList('Choices', left)}</Grid>
      <Grid item xs>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>{customList('Chosen', right)}</Grid>
    </Grid>
  );
}
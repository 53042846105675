import React, { SyntheticEvent } from "react"
import { Box, Typography, Stack, Button } from "@mui/material"
import moment from "moment"

import { categories } from "../../api"
import ScoreCardTable from "./ScorcardTable"

const ViewScoreCard = (props: any) => {
  const {
    scorecardData,
    startDate,
    middleDate,
    endDate,
    selectedCategory,
    setSelectedCategory,
    generateScoreCard,
    kpiList
  } = props

  const endMonth = moment(endDate).format("MMMM YYYY")
  const startMonth = moment(startDate).format("MMMM YYYY")
  const middleMonth = moment(middleDate).format("MMMM YYYY")

  const handleCategoryClick = (category: any) => (e: SyntheticEvent) => {
    setSelectedCategory(category)
    generateScoreCard(e, category)
  }

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h3" sx={{ fontSize: "1rem", fontWeight: "700" }}>
          {startMonth} - {middleMonth} - {endMonth}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Typography variant="subtitle1" sx={{ fontSize: "1rem" }}>
            Show by Category:
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            {categories?.map(category => {
              const selected = selectedCategory === category?.id

              return (
                <Button
                  key={`Category-${category?.id}`}
                  variant={selected ? "contained" : "outlined"}
                  sx={{
                    background: selected ? "#140A9A" : "#FFFFFF",
                    border: "1px solid",
                    borderColor: selected
                      ? `#140A9A`
                      : "rgba(20, 10, 154, 0.5)",
                    color: selected ? "#FFFFFF" : "#140A9A",
                    padding: "0.4rem 1rem",
                    marginTop: "0 !important"
                  }}
                  onClick={
                    selected ? undefined : handleCategoryClick(category?.id)
                  }
                >
                  {category?.label}
                </Button>
              )
            })}
          </Stack>
        </Stack>
      </Stack>
      <Box>
        {scorecardData?.length > 0 && (
          <ScoreCardTable
            scorecardData={scorecardData}
            startDate={startDate}
            endDate={endDate}
            middleDate={middleDate}
            kpiList={kpiList}
          />
        )}
      </Box>
    </Box>
  )
}

export default ViewScoreCard

import {
  createTheme,
  Grid,
  Box,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Button,
  InputLabel
} from "@mui/material"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../context/AuthContext"
import { ReactComponent as InfoIcon } from "../../assests/images/info.svg"
import { ReactComponent as TickIcon } from "../../assests/images/tick.svg"
import { useLocation, useNavigate } from "react-router-dom"
import ViewKpiReportData from "./viewKpiReportData"
import { getSubKpiDataType, getSubKpiType, getUserData, processReportData } from "../../utils/util"

const ViewKpiReport = () => {
  const location = useLocation()
  const nav = useNavigate()
  const [reportData, setReportData] = useState<any[]>([])
  const { fetchReportData, getKpi } = useAuthContext()
  const [kpiData, setKpiData] = useState({} as any)

  const [selectedKpiId, setSelectedKpiId] = useState(null)
  const [date, setDate] = useState({} as any)
  const [selectedSubKpis, setSelectedSubKpis] = useState([])
  const [selectedCompany, setSelectedCompany] = useState("")
  const [selectedCustomer, setSelectedCustomer] = useState("")
  const [selectedCompanyName, setSelectedCompanyName] = useState("")
  const [selectedCustomerName, setSelectedCustomerName] = useState("")
  const [selectedYear, setSelectedYear] = useState("")
  const [userType, setUserType] = useState("")
  const [selectedKpiPermission, setSelectedKpiPermission] = useState("")

  useEffect(() => {
    setUserType(getUserData()?.user_type)
    setSelectedKpiId(location?.state?.kpiId)
    setDate(location?.state?.date)
    setSelectedSubKpis(location?.state?.selectedSubKpis)
    setSelectedCompany(location?.state?.company)
    setSelectedCustomer(location?.state?.customer)
    setSelectedCompanyName(location?.state?.companyName)
    setSelectedCustomerName(location?.state?.customerName)
    setSelectedYear(location?.state?.year)
    setSelectedKpiPermission(location?.state?.kpiPermission)
  }, [])

  useEffect(() => {
    if (selectedKpiId) {
      getKpi(selectedKpiId).then((resp: any) => {
        setKpiData(resp)
      })
    }
  }, [selectedKpiId])

  useEffect(() => {
    if (selectedKpiId && selectedSubKpis.length) {
      const payload = {
        kpi: selectedKpiId,
        sub_kpi: selectedSubKpis?.map((obj: any) => obj.id),
        start_date: date.startDate,
        end_date: date.endDate,
        company: selectedCompany ? Number(selectedCompany) : null,
        customer: selectedCustomer ? Number(selectedCustomer) : null
      }

      fetchReportData(payload).then((resp: any) => {
        const selectedCustomerIds = selectedCustomer ? [selectedCustomer] : null;
        const selectedCompanyIds = selectedCompany ? [selectedCompany] : null;
        const selectedKpi = location?.state?.selectedKpi;
        setReportData(
          processReportData({
            reportData: resp.data,
            selectedSubKpis,
            selectedCustomerIds,
            selectedCompanyIds,
            selectedKpi,
            userType
          })
        )
      })
    }
  }, [
    selectedKpiId,
    selectedSubKpis,
    date,
    selectedCompany,
    selectedCustomer,
    location,
    userType
  ])

  const handleCancel = () => {
    nav("/kpi-report")
  }

  const handleEditReport = () => {
    nav(`/kpi-report/edit/${kpiData?.id}`, {
      state: {
        selectedKpi: location?.state?.selectedKpi,
        kpiId: kpiData.id,
        date: date,
        selectedSubKpis: selectedSubKpis,
        company: selectedCompany,
        customer: selectedCustomer,
        year: selectedYear,
        companyName: selectedCompanyName,
        customerName: selectedCustomerName,
        kpiPermission: selectedKpiPermission
      }
    })
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 2,
          ml: 30,
          mr: 5,
          mt: 15,
          mb: 2,
          backgroundColor: "#F8F9FC"
        }}
      >
        <Grid container display={"flex"} flexDirection={"row"} sx={{ mt: 1 }}>
          <Grid item xs={3} display={"flex"} justifyContent={"flex-start"}>
            <h2>View KPI Report</h2>
          </Grid>
          <Grid item xs={12}>
            {kpiData?.performance_indicator ? (
              <TextField
                margin="normal"
                fullWidth
                label="KPI"
                className="text-field"
                id="kpi"
                size="small"
                name="kpi"
                autoFocus
                aria-readonly
                value={kpiData?.performance_indicator}
                style={{ background: "#FFFFFF" }}
                InputProps={{
                  readOnly: true
                }}
              />
            ) : null}
          </Grid>
          <Grid item xs={1} display={"flex"} alignItems={"flex-start"} sx={{ mt: 2 }}>
            <InputLabel>Sub KPI</InputLabel>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ p: 2, border: '1px solid #AFAF9B', borderRadius: "3px", background: "#FFFFFF" }}>
              <Typography>
                <Grid container spacing={2} alignItems="center">
                  {selectedSubKpis && selectedSubKpis?.length
                    ? selectedSubKpis?.map((subKpi: any, index: any) => {
                      const type = getSubKpiType(subKpi.type);
                      return (
                        <Grid item xs={4} key={index}>
                          <Typography
                            sx={{ mt: 1, mb: 1 }}
                            style={{
                              cursor: "pointer",
                              borderRadius: 4,
                              color: "#FFFFFF",
                              backgroundColor: "#000099"
                            }}
                          >
                            <TickIcon /> {subKpi.name}
                            {
                              <Tooltip title={
                                <>
                                  <div>
                                    <b>Sub KPI Name: {subKpi.name}</b>
                                  </div>
                                  <br />
                                  <div>
                                    Type: {type}
                                  </div>
                                  <br />
                                  <div>
                                    Data Type: {getSubKpiDataType(subKpi.data_type)}
                                  </div>
                                  <br />
                                  <div>
                                    Description: {subKpi.explanatory_text}
                                  </div>
                                </>}>
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </Typography>
                        </Grid>
                      )
                    })
                    : null}
                </Grid>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <TextField
              margin="normal"
              fullWidth
              label="Financial Year"
              className="text-field"
              id="kpi"
              size="small"
              name="kpi"
              autoFocus
              aria-readonly
              value={(date && date.startDate && date.endDate && date?.startDate?.slice(0, 4) + "-" + date?.endDate?.slice(2, 4)) || ""}
              style={{ background: "#FFFFFF" }}
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          {selectedCompany ? (
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                margin="normal"
                fullWidth
                label="Company"
                multiline
                className="text-field"
                id="select-company"
                size="small"
                name="company"
                autoFocus
                aria-readonly
                value={selectedCompanyName}
                style={{ background: "#FFFFFF" }}
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          ) : null}
          {selectedCustomer ? (
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                margin="normal"
                fullWidth
                label="Customer"
                multiline
                className="text-field"
                id="customer"
                size="small"
                name="customer"
                autoFocus
                aria-readonly
                value={selectedCustomerName}
                style={{ background: "#FFFFFF" }}
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          ) : null}
          <Grid item xs={2} display={"flex"} justifyContent={"flex-start"}>
            <Typography sx={{ fontWeight: 500, fontSize: "16px", mt: 2 }}>
              Report Name
            </Typography>
          </Grid>
          {selectedSubKpis &&
            selectedSubKpis?.length &&
            selectedSubKpis?.sort((a: any, b: any) => a.data_type - b.data_type).map((subKpi: any, index: any) => {
              return (
                <ViewKpiReportData
                  selectedKpi={kpiData}
                  kpiId={kpiData.id}
                  key={subKpi.id}
                  subKpi={subKpi}
                  date={date}
                  company={selectedCompany}
                  customer={selectedCustomer}
                  reportData={reportData}
                />
              )
            })}
          <Grid container display={"flex"} sx={{ mt: 2, mb: 4 }}>
            <Grid
              item
              xs={6}
              sx={{ pr: 4 }}
              justifyContent={"flex-end"}
              display={"flex"}
            >
              <Button
                onClick={handleCancel}
                variant="contained"
                style={{ background: "#F8F9FC", color: "#000000" }}
              >
                Cancel
              </Button>
            </Grid>
            {Number(userType) === 1 || (Number(userType) === 3 && Number(selectedKpiPermission) === 2) ?
              <Grid item xs={2} display={"flex"}>
                <Button
                  onClick={handleEditReport}
                  variant="contained"
                  style={{ background: "#000099" }}
                >
                  Edit Report
                </Button>
              </Grid>
              : null}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ViewKpiReport

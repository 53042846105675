import moment from "moment"
import {
  DATA_TYPE_DECIMAL, DATA_TYPE_PERCENTAGE, categories,
  permissions, priorities, statuses, subKPIDataType, subKpiType, usertype
} from "../api"

export const getCategory = (category: number | null): string | undefined => {
  switch (category) {
    case 1:
      return categories[0].label
    case 2:
      return categories[1].label
    case 3:
      return categories[2].label
    case 4:
      return categories[3].label
    case 5:
      return categories[4].label
    default:
      return undefined
  }
}

export const getPriority = (priority: number | null): string | undefined => {
  switch (priority) {
    case 1:
      return priorities[0].label
    case 2:
      return priorities[1].label
    case 3:
      return priorities[2].label
    default:
      return undefined
  }
}

export const getStatus = (status: number | null): string | undefined => {
  switch (status) {
    case 1:
      return statuses[0].label
    case 2:
      return statuses[1].label
    default:
      return undefined
  }
}

export const getPermission = (
  permission: number | null
): string | undefined => {
  switch (permission) {
    case 1:
      return permissions[0].label
    case 2:
      return permissions[1].label
    default:
      return undefined
  }
}

export const getUserType = (status: number | null): string | undefined => {
  switch (status) {
    case 1:
      return usertype[0].label
    case 2:
      return usertype[1].label
    case 3:
      return usertype[2].label
    case 4:
      return usertype[3].label
    default:
      return undefined
  }
}

export const getSubKpiColor = (subKpi: any) => {
  if (subKpi?.type === 2) {
    return "#9BB9B9"
  }
  if (subKpi?.type === 3) {
    return "#AFAF9B"
  }
  return "#DDDDDD"
}

export const getSubKpiType = (subKpi: number | null): string | undefined => {
  switch (subKpi) {
    case 1:
      return subKpiType[0].label
    case 2:
      return subKpiType[1].label
    case 3:
      return subKpiType[2].label
    default:
      return undefined
  }
}

export const getSubKpiDataType = (dataTypeId: number | string): string => {
  return subKPIDataType.find((type: any) => type.id === dataTypeId)?.label || '';
}

export const getSubKpiDataTypeSymbol = (dataTypeId: number | string): string => {
  return subKPIDataType.find((type: any) => type.id === dataTypeId)?.symbol || '';
}

export const getUserData = () => {
  if (localStorage.getItem("user")) {
    const userDataString = localStorage.getItem("user") as string;
    const userData = JSON.parse(userDataString)
    return userData
  }
}

export const getMonthsInPeriod = (start: any, end: any, format: any = 'YYYY-MM-DD') => {
  let startDate = moment(start, "YYYY-MM-DD");
  let endDate = moment(end, "YYYY-MM-DD").endOf("month");

  const allMonthsInPeriod = [];

  while (startDate.isBefore(endDate)) {
    allMonthsInPeriod.push(startDate.format(format));
    startDate = startDate.add(1, "month");
  }

  return allMonthsInPeriod;
}

export const checkShowCompanyAndShowCustomer = (kpi: any, subKpi: any, userType: any) => {
  let showCustomer = false;
  let showCompany = false;

  if (
    (userType === usertype[0].id || userType === usertype[1].id) &&
    kpi?.permission === permissions[0].id &&
    subKpi?.type === subKpiType[1].id
  ) {
    showCompany = true;
  } else if (
    (userType === usertype[0].id || userType === usertype[1].id) &&
    kpi?.permission === permissions[1].id &&
    subKpi?.type === subKpiType[1].id
  ) {
    showCompany = true;
  } else if (
    (userType === usertype[0].id || userType === usertype[1].id) &&
    kpi?.permission === permissions[1].id &&
    subKpi?.type === subKpiType[2].id
  ) {
    showCompany = true;
    showCustomer = true;
  } else if (
    (userType === usertype[2].id || userType === usertype[3].id) &&
    kpi?.permission === permissions[1].id &&
    subKpi?.type === subKpiType[2].id
  ) {
    showCustomer = true;
  }

  return [showCompany, showCustomer];
}

export const processReportData = ({
  reportData,
  selectedSubKpis,
  selectedCustomerIds,
  selectedCompanyIds,
  selectedKpi,
  userType
}: {
  reportData: any,
  selectedSubKpis: any,
  selectedCustomerIds: any,
  selectedCompanyIds: any,
  selectedKpi: any,
  userType: any
}) => {
  console.log({ selectedSubKpis, selectedCustomerIds, selectedCompanyIds, selectedKpi, userType })
  const filteredData = reportData?.filter((entry: any) => {
    const subKpi = selectedSubKpis?.find(
      (subKPIId: any) => subKPIId?.id?.toString() === entry.sub_kpi_details?.id?.toString()
    )
    const [showCompany, showCustomer] = checkShowCompanyAndShowCustomer(
      selectedKpi, subKpi, userType
    )
    return (
      subKpi &&
      (!selectedCustomerIds || !showCustomer ||
        selectedCustomerIds?.some((selectedCustomer: any) => selectedCustomer?.toString() === entry.customer?.toString())
      ) &&
      (!selectedCompanyIds || !showCompany ||
        selectedCompanyIds?.some((selectedCompany: any) => selectedCompany?.toString() === entry.company?.toString())
      )
    )
  });

  console.log({ filteredData })
  // sort by updated date in descending order and keep only last updated records
  const processedData: any = filteredData?.sort((a: any, b: any) => {
    //return b.id - a.id
    const dateA: any = a?.updated_at ? moment(a.updated_at).format('x') : null
    const dateB: any = b?.updated_at ? moment(b.updated_at).format('x') : null
    if (dateA && dateB) {
      return dateB - dateA
    }
    // Handle cases where date is undefined or null
    return 0
  })?.reduce((subKPIs: any, entry: any) => {
    const subKPI = entry.sub_kpi_details
    const subKPIId = subKPI.id
    const companyId = entry?.company ? '-' + entry?.company : '-cy';
    const customerId = entry?.customer ? '-' + entry?.customer : '-cx';
    const date = entry.date ? moment(entry.date).format('-MMM-YY') : '';
    const subKPIKey = `${subKPIId}${companyId}${customerId}${date}`;

    if (!subKPIs[subKPIKey]) {
      subKPIs[subKPIKey] = entry;
    }

    return subKPIs;
  }, {})

  console.log({ processedData })
  console.log('count', Object.keys(processedData)?.length)
  return Object.values(processedData || {});
}

const customParseFloat = (number: any) => {
  if (isNaN(parseFloat(number)) === false) {
    let toFixedLength = 0;
    let str = String(number);

    // You may add/remove seperator according to your needs
    [".", ","].forEach(seperator => {
      let arr = str.split(seperator);
      if (arr.length === 2) {
        toFixedLength = arr[1].length;
      }
    })

    return parseFloat(str).toFixed(toFixedLength);
  }

  return number; // Not a number, so you may throw exception or return number itself
}
export const isDecimalFillRate = (dataType: any) => dataType === DATA_TYPE_PERCENTAGE || dataType === DATA_TYPE_DECIMAL;

export const getFillRate = (subKpi: any, report: any, prevValue: any = '', skipParse: boolean = false) => {
  const endDotCheck = /\.$/;
  let fillRate = isDecimalFillRate(subKpi?.data_type) ? report?.fill_rate : report?.other_fill_rate;
  fillRate = fillRate?.toString().replace(/[^\d.]/, '');

  if (fillRate === undefined || fillRate === null || fillRate?.toString()?.length <= 0) {
    return null;
  }

  if (
    (Number.isNaN(fillRate)) ||
    (subKpi.data_type === DATA_TYPE_PERCENTAGE && fillRate > 100) ||
    (subKpi.data_type === DATA_TYPE_DECIMAL && fillRate > 9999.99)
  ) {
    return prevValue;
  }

  // skipEndDot set allow dot in the end. This is for allowing . while editing
  return subKpi.data_type === DATA_TYPE_PERCENTAGE ||
    subKpi.data_type === DATA_TYPE_DECIMAL
    ? endDotCheck.test(fillRate) && skipParse ? fillRate : customParseFloat(fillRate)
    : Math.floor(fillRate)
}

export const isCompanyUser = (userType: any) => {
  return usertype?.some((type: any) => (
    (
      type?.label === "COMPANY_ADMIN" ||
      type?.label === "COMPANY_STAFF"
    ) &&
    type?.id === userType
  ));
}

// function to delete cookies
export const deleteCookies = () => {
  const Cookies = document.cookie.split(';');

  // set 1 Jan, 1970 expiry for every cookies
  for (let i = 0; i < Cookies.length; i++)
    document.cookie = Cookies[i] + '=;expires=' + new Date(0).toUTCString();
};

import Button from "@mui/material/Button"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Typography from "@mui/material/Typography"
import { Divider, Grid, IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

export default function DeletePopUp(props: any) {
  const { userId, email, handleCloseDeletePopUp, handleDelete } = props

  return (
    <div>
      <Dialog
        onClose={handleCloseDeletePopUp}
        open={true}
        PaperProps={{
          sx: {
            width: 400,
            maxHeight: 400,
            borderRadius: "10px"
          }
        }}
      >
        <DialogTitle
          style={{ display: "flex", fontSize: "1rem" }}
          alignSelf={"center"}
          fontWeight={600}
        >
          Delete Account
          <IconButton
            aria-label="close"
            onClick={handleCloseDeletePopUp}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Divider style={{ width: "100%" }} sx={{ mb: 2 }}></Divider>
          <Typography style={{ fontSize: 14, textAlign: "center" }}>
            {`Are you sure you want to delete ${email} User account ?`}
          </Typography>
        </DialogContent>
        <DialogActions style={{ display: "flex", alignSelf: "center" }}>
          <Grid container display={"flex"} direction={"row"} sx={{ mb: 2 }}>
            <Grid item xs={5}>
              <Button
               variant="contained"
               style={{ background: "#F8F9FC", color: "#000000" }}
                onClick={handleCloseDeletePopUp}
              >
                Cancel
              </Button>
              </Grid>
              <Grid item xs={6}>
              <Button
                onClick={() => handleDelete(userId)}
                variant="contained"
                sx={{ ml: 2 }}
                style={{
                  background: "#000099",
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

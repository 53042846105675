import { Box, Button, Grid } from "@mui/material"
import { useAuthContext } from "../../context/AuthContext"
import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { Line } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement
} from "chart.js"
import type { ChartData, ScatterDataPoint } from "chart.js"
import "chartjs-adapter-moment"
import html2canvas from "html2canvas"
import { ReactComponent as ShareIcon } from "../../assests/images/share.svg"
import { toast } from "react-toastify"
import { checkShowCompanyAndShowCustomer, getFillRate, getMonthsInPeriod, getSubKpiDataType, getUserData, processReportData } from "../../utils/util"
import moment from "moment"
import { DATA_TYPE_DAYS, DATA_TYPE_PERCENTAGE, DATA_TYPE_RECORDS } from "../../api"
import { APPCONFIG } from "../../app-config"

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement
)

const ViewChart = () => {
  const captureRef = useRef(null)
  const { fetchChartData, uploadChartImage } = useAuthContext()
  const location = useLocation()
  const [responseData, setResponseData] = useState<any[]>([])
  const [selectedKpiId, setSelectedKpiId] = useState([] as any)

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [selectedSubKpiIds, setSelectedSubKpiIds] = useState([])
  const [selectedCompany, setSelectedCompany] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState([])
  const [selectedtarget, setSelectedTarget] = useState([])
  const [selectedSubKpis, setSelectedSubKpis] = useState<any>([])
  const [chartsData, setChartsData] = useState<ChartData<"line", (number | ScatterDataPoint | null)[], unknown>>([] as any);
  const [imageKey, setImageKey] = useState("")
  const [showCopyUrl, setShowCopyUrl] = useState(false)
  const [subKpiNamesWithoutData, setSubKpiNamesWithoutData] = useState([])
  const [companyNameWithoutData, setCompanyNameWithoutData] = useState([])

  useEffect(() => {
    console.log('ViewChart state: ',location?.state)
    setSelectedKpiId(location?.state?.kpiId)
    setStartDate(location?.state?.startDate)
    setEndDate(location?.state?.endDate)
    setSelectedSubKpiIds(location?.state?.selectedSubKpiIds)
    setSelectedCompany(location?.state?.company)
    setSelectedCustomer(location?.state?.customer)
    setSelectedTarget(location?.state?.target)
    setSelectedSubKpis(location?.state?.selectedSubKpis)
  }, [])

  useEffect(() => {
    if (startDate && endDate && selectedKpiId && selectedSubKpiIds) {
      const payload = {
        kpi: selectedKpiId,
        sub_kpi: selectedSubKpiIds,
        start_date: startDate,
        end_date: endDate,
        company: selectedCompany?.length ? selectedCompany?.map((comp: any) => comp.id) : null,
        customer: selectedCustomer?.length ? selectedCustomer?.map((cust: any) => cust.id) :  null
      }
      fetchChartData(payload).then((resp: any) => {
        const selectedKpi =  location?.state?.selectedKpi;
        // console.log('processReportData::', processReportData({
        //   reportData: resp.data,
        //   selectedKpi,
        //   selectedSubKpis,
        //   selectedCustomerIds: payload.customer,
        //   selectedCompanyIds: payload.company,
        //   userType: getUserData()?.user_type,
        // }))
        let isDataPresent = false;
        let resp_data = resp?.data
        if(resp_data?.length > 0){
          resp_data.forEach((item:any)=>{
            if(item?.fill_rate || item?.other_fill_rate){
              isDataPresent = true;
            }
          })
        }
        if(!isDataPresent){
          resp_data = []
        }
        setResponseData(processReportData({
          reportData: resp_data,
          selectedKpi,
          selectedSubKpis,
          selectedCustomerIds: payload.customer,
          selectedCompanyIds: payload.company,
          userType: getUserData()?.user_type,
        }))
      })
    }
  }, [
    selectedKpiId,
    startDate,
    endDate,
    selectedSubKpiIds,
    selectedCompany,
    selectedCustomer,
    selectedtarget
  ])

  // Prepare data for the chart
  const chartData: any = {
    datasets: [] // Array to hold the dataset objects
  }

  // Generate a random color for each dataset
  function getRandomColor() {
    const letters = "0123456789ABCDEF"
    let color = "#"
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  useEffect(() => {
    chartData.labels = getMonthsInPeriod(startDate, endDate, "MMM YY");
    // Extract sub-KPIs
    const selectedKpi =  location?.state?.selectedKpi;
    const subKPIs = responseData?.reduce((subKPIs, entry) => {
      const subKPI = entry.sub_kpi_details
      const subKPIId = subKPI.id
      const [showCompany, showCustomer ] = checkShowCompanyAndShowCustomer(
        selectedKpi, subKPI, getUserData()?.user_type,
      )
      const companyId = showCompany && entry?.company ? '-' + entry?.company :  'cy';
      const customerId = showCustomer && entry?.customer ? '-' + entry?.customer : 'cx';
      const subKPIKey = `${subKPIId}${companyId}${customerId}`;
      const company: any = companyId && selectedCompany?.find((comp: any) => entry?.company === comp?.id);
      const customer: any = customerId && selectedCustomer?.find((cust: any) => entry?.customer === cust?.id);
      const companyName = company?.name ? ' > ' + company.name : '';//' > cy'
      const customerName = customer?.name ? ' > ' + customer.name : '';//' > cx'
      const subKPIName = `${subKPI.name}${companyName}${customerName}`
      if (!subKPIs[subKPIKey]) {
        subKPIs[subKPIKey] = {
          id: subKPIId,
          customer: customer,
          company: company,
          name: subKPIName,
          data: []
        }
      }

      subKPIs[subKPIKey].data.push({
        date: entry.date,
        fill_rate: getFillRate(subKPI, entry)
      })

      return subKPIs
    }, {})

    // Convert sub-KPIs object into an array
    const subKPIsArray = Object.values(subKPIs)

    // Sort the data points by date for each sub-KPI
    subKPIsArray.forEach((subKPI: any) => {
      subKPI.data.sort((a: any, b: any) => {
        const dateA: any = a ? moment(a.date).format('x') : null
        const dateB: any = b ? moment(b.date).format('x') : null
        if (dateA && dateB) {
          return dateA - dateB
        }
        // Handle cases where either a.date or b.date is undefined or null
        return 0
      })
    })

    // Iterate over each sub-KPI and populate the chartData object
    let companyWithData:any = []
    subKPIsArray.forEach((subKPI: any, index) => {
      const dataPoints = subKPI.data.map((dataPoint: any) => {
        const monthYear = moment(dataPoint?.date).format("MMM YY")
        return {
          x: monthYear,
          y: dataPoint?.fill_rate
        }
      })

      const dataset: any = {
        label: subKPI?.name,
        data: dataPoints,
        borderColor: getRandomColor()
        // fill: false,
      }
      companyWithData.push(subKPI.company)

      chartData.datasets.push(dataset)
      setChartsData(chartData)
    })

    if (selectedtarget?.length) {
      selectedtarget?.forEach((subKpiTarget: any) => {
        // Add the "target" dataset
        const targetDataPoints: any = []
        chartData.labels.forEach((monthYear: any) => {
          targetDataPoints.push({
            x: monthYear,
            y: subKpiTarget?.target
          })
        });

        const targetDataset: any = {
          label: `${subKpiTarget?.name} - Target`,
          data: targetDataPoints,
          borderColor: getRandomColor(),
          borderDash: [6, 6]
        }
        chartData.datasets.push(targetDataset)
      });
    }
    console.log('graph chart data:',chartData)

    setChartsData(chartData)

    if (responseData.length) {
      const dataSubKPIIds = responseData.map(data => data.sub_kpi_details.id)
      const subKpiIdsWithoutData: any = selectedSubKpiIds.filter(
        subKPIId => !dataSubKPIIds.includes(subKPIId)
      )
      const matchedNames: any = selectedSubKpis
        .filter((entry: any) => subKpiIdsWithoutData.includes(entry?.id))
        .map((entry: any) => entry.name)
      setSubKpiNamesWithoutData(matchedNames)
    } else {
      setSubKpiNamesWithoutData(selectedSubKpis.map((entry:any) => entry.name))
    }
    //company with no data start
    console.log("Final selection companyWithData:", companyWithData)
    console.log("Final selection selectedCompany:", selectedCompany)
    let arrCompanyWithoutDataName:any = []
    if(selectedCompany && selectedCompany.length>0){
      selectedCompany.forEach((selected_comp:any) => {
        let companyIndex = companyWithData.findIndex((comp_data:any)=>comp_data.id===selected_comp.id)
        if(companyIndex===-1){
          arrCompanyWithoutDataName.push(selected_comp.name)
        }
      });
    }
    console.log('arrCompanyWithoutDataName: ',arrCompanyWithoutDataName)
    setCompanyNameWithoutData(arrCompanyWithoutDataName);
    //company with no data end

  }, [responseData])

  const getDateRange = () => {
    return `${moment(startDate).format("MMM YY")} - ${moment(endDate).format("MMM YY")}`
  }

  const handleCaptureAndUpload = () => {
    captureRef &&
      captureRef.current &&
      html2canvas(captureRef?.current).then(canvas => {
        canvas.toBlob((blob: any) => {
          const formData = new FormData()
          formData.append("image", blob, "screenshot.png")
          uploadChartImage(formData).then((resp: any) => {
            //console.log(resp)
            setImageKey(resp?.key)
          })
          setShowCopyUrl(true)
        })
      })
  }

  const handleCopyUrl = () => {
    const text = `${APPCONFIG.axiosBaseUrl}/image/${imageKey}`
    navigator.clipboard
      .writeText(text)
      .then(() => {
        //console.log("Text copied to clipboard:", text)
        toast.success("url copied")
      })
      .catch(error => {
        console.error("Error copying text to clipboard:", error)
      })
  }

  const options = {
    plugins: {
      legend: {
        display: true,
        labels: {
          boxHeight: 2
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: (selectedSubKpis?.[0]?.data_type === DATA_TYPE_DAYS
            || selectedSubKpis?.[0]?.data_type === DATA_TYPE_RECORDS),
          text: getSubKpiDataType(selectedSubKpis?.[0]?.data_type)
        },
        ticks: {
          callback: (value: any) => {
            return selectedSubKpis?.[0]?.data_type === DATA_TYPE_PERCENTAGE ?  value + "%" : value ;
          }
        }
      }
    }
  };
  

  return (
    <>
      <Grid
        container
        sx={{
          ml: 30,
          mr: 5,
          mt: 15,
          mb: 2
        }}
      >
        {!showCopyUrl && (
          <Grid
            item
            xs={9}
            display={"flex"}
            justifyContent={"flex-end"}
            sx={{ mt: 2, ml: 6 }}
          >
            {responseData?.length ? (
              <ShareIcon onClick={handleCaptureAndUpload} />
            ) : null}
          </Grid>
        )}
      </Grid>
      <Box
        sx={{
          ml: 30,
          mr: 5,
          mb: 2,
          border: "1px solid",
          borderColor: "#EBEBEB",
          borderRadius: "16px"
        }}
      >
        <div ref={captureRef} style={{ margin: 16 }}>
          {showCopyUrl ? (
            <Grid
              container
              display={"flex"}
              flexDirection={"row"}
              sx={{ m: 2 }}
            >
              <Grid
                item
                xs={6}
                display={"flex"}
                justifyContent={"center"}
              ></Grid>
              <Grid
                item
                xs={5}
                display={"flex"}
                justifyContent={"flex-center"}
                sx={{ mr: 1 }}
              >
                <Button
                  variant="contained"
                  style={{ background: "#F8F9FC", color: "#000000" }}
                  onClick={() => {
                    setShowCopyUrl(!showCopyUrl)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleCopyUrl}
                  variant="contained"
                  sx={{ ml: 2 }}
                  style={{ background: "#000099" }}
                >
                  Copy Url
                </Button>
              </Grid>
            </Grid>
          ) : null}
          <Grid container display={"flex"} flexDirection={"row"} sx={{ mt: 2 }}>
            <Grid item xs={6} display={"flex"} justifyContent={"flex-start"}>
              <h5>{responseData[0]?.kpi_name}</h5>
            </Grid>
            <Grid item xs={5} display={"flex"} justifyContent={"flex-end"}>
              <h5>{responseData?.length ? getDateRange() : null}</h5>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {chartsData &&
            chartsData?.datasets &&
            chartsData?.datasets?.length ? (
              <Line data={chartsData} options={options} />
            ) : null}
          </Box>
          {
              companyNameWithoutData &&
              companyNameWithoutData.length>0 &&
              chartsData?.datasets &&
              chartsData?.datasets?.length ? (
              <Grid item xs={12} sx={{ m: 2, color: "#FF7A00" }}>
              {`Note: Please note that `}
              <strong>{companyNameWithoutData?.join(", ")}</strong>
              {` currently has no available data.`}
              </Grid>
              ):null
          }
          {subKpiNamesWithoutData.length ? (
            <Grid container>
              <Grid item sx={{ m: 2, color: "#FF7A00" }}>
                {`Note: Please note that the selected KPI (`}
                <strong>{subKpiNamesWithoutData?.join(", ")}</strong>
                {`) currently has no available data.`}
              </Grid>
            </Grid>
          ) : null}
          {!selectedtarget?.length ? (
            <Grid container>
              <Grid item sx={{ m: 2, color: "#FF7A00" }}>
                {`Note: Please note that their in no selected target value`}
              </Grid>
            </Grid>
          ) : null}
        </div>
      </Box>
    </>
  )
}

export default ViewChart

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  InputLabel
} from "@mui/material"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../context/AuthContext"
import { useLocation, useNavigate } from "react-router-dom"
import { ReactComponent as InfoIcon } from "../../assests/images/info.svg"
import { ReactComponent as TickIcon } from "../../assests/images/tick.svg"
import CreateKpiReportData from "./createKpiReportData"
import { checkShowCompanyAndShowCustomer, getMonthsInPeriod, getSubKpiDataType, getSubKpiType, getUserData, isCompanyUser, processReportData } from "../../utils/util"
import { subKpiType } from "../../api"

const CreateKpiReport = () => {
  interface SavePayload {
    new_record: {
      kpi: number,
      sub_kpi: number,
      date: string,
      fill_rate: any,
      other_fill_rate: any,
      company: any,
      customer: any
    }[];
    update_record: {
      id: any,
      kpi: number,
      sub_kpi: number,
      date: string,
      fill_rate: any,
      other_fill_rate: any,
      company: any,
      customer: any
    }[];
  }

  const { fetchReportData, getKpi, addOrEditReportData } = useAuthContext()
  const location = useLocation()
  const nav = useNavigate()
  const [reportData, setReportData] = useState<any[]>([])
  const [savePayload, setSavePayload] =
    useState<SavePayload>({ new_record: [], update_record: [] })
  const [kpiData, setKpiData] = useState({} as any)
  const [selectedKpiId, setSelectedKpiId] = useState(null)
  const [date, setDate] = useState({} as any)
  const [selectedSubKpis, setSelectedSubKpis] = useState([])
  const [selectedCompany, setSelectedCompany] = useState("")
  const [selectedCustomer, setSelectedCustomer] = useState("")
  const [selectedCompanyName, setSelectedCompanyName] = useState("")
  const [selectedCustomerName, setSelectedCustomerName] = useState("")
  const [selectedKpiPermission, setSelectedKpiPermission] = useState("")
  const [selectedYear, setSelectedYear] = useState("")
  const userData = getUserData()
  const userType = userData.user_type;

  const [activeChild, setActiveChild] = useState(null)

  const handleToggle = (childId: any) => {
    if (activeChild === childId) {
      setActiveChild(null) // Cancel if the same child is clicked again
    } else {
      setActiveChild(childId)
    }
  }

  useEffect(() => {
    setSelectedKpiId(location?.state?.kpiId)
    setDate(location?.state?.date)
    setSelectedSubKpis(location?.state?.selectedSubKpis)
    setSelectedCompany(location?.state?.company)
    setSelectedCustomer(location?.state?.customer)
    setSelectedCompanyName(location?.state?.companyName)
    setSelectedCustomerName(location?.state?.customerName)
    setSelectedYear(location?.state?.year)
    setSelectedKpiPermission(location?.state?.kpiPermission)
  }, [])

  useEffect(() => {
    if (selectedKpiId) {
      getKpi(selectedKpiId).then((resp: any) => {
        setKpiData(resp)
      })
    }
  }, [selectedKpiId])

  useEffect(() => {
    if (selectedKpiId && selectedSubKpis.length) {
      const payload = {
        kpi: selectedKpiId,
        sub_kpi: selectedSubKpis?.map((obj: any) => obj.id),
        start_date: date.startDate,
        end_date: date.endDate,
        company: selectedCompany ? Number(selectedCompany) : null,
        customer: selectedCustomer ? Number(selectedCustomer) : null
      }

      fetchReportData(payload).then((resp: any) => {
        const selectedCustomerIds = selectedCustomer ? [selectedCustomer] : null;
        const selectedCompanyIds = selectedCompany ? [selectedCompany] : null;
        const selectedKpi = location?.state?.selectedKpi;
        setReportData(
          processReportData({
            reportData: resp.data,
            selectedKpi,
            selectedSubKpis,
            selectedCustomerIds,
            selectedCompanyIds,
            userType: userType,
          })
        )
      })
    }
  }, [
    selectedKpiId,
    selectedSubKpis,
    date,
    selectedCompany,
    selectedCustomer,
    location
  ])

  const getUniqueRecords = (values: any) => {
    const uniqueValues: any = {};
    values.map((value: any) => {
      uniqueValues[`${value.kpi}-${value.sub_kpi}-${value.date}`] = value;
    })

    return Object.values(uniqueValues);
  }

  const handleSaveEditRow = (
    monthValues: any,
    dateRange: any,
    kpiId: any,
    subKpiId: any,
    company: any,
    customer: any,
    selectedKpi: any,
  ) => {
    const [startYear] = date?.startDate?.split("-")
    const [endYear] = date?.endDate?.split("-")
    const monthsInPeriod = getMonthsInPeriod(`${startYear}-04-01`, `${endYear}-03-01`)
    const defaultReportData: any = [];
    monthsInPeriod.forEach(date => {
      selectedSubKpis?.forEach((subKpi: any) => {
        if (!reportData?.some((report: any) => report.date === date && report.sub_kpi === subKpi.id)) {
          const [showCompany, showCustomer] = checkShowCompanyAndShowCustomer(
            selectedKpi, subKpi, getUserData()?.user_type
          )
          const useCompany = isCompanyUser(userType) &&
            (subKpi?.type === subKpiType[1].id || subKpi?.type === subKpiType[2].id)
            && selectedCompany;

          defaultReportData.push({
            date,
            fill_rate: null,
            other_fill_rate: null,
            kpi: kpiId,
            sub_kpi: subKpiId,
            company: (useCompany ? useCompany : showCompany ? company : null) || null,
            customer: (showCustomer ? customer : null) || null
          })
        }
      });
    });

    const combinedMonthValues = [
      ...defaultReportData,
      ...(savePayload?.new_record || []),
      ...(savePayload?.update_record || []),
      ...(monthValues || [])
    ]
    //console.log({combinedMonthValues})

    // Filter duplicate records
    const filteredRecords: any = getUniqueRecords(combinedMonthValues)

    //console.log({filteredRecords})
    const updatedRecords = filteredRecords.map((record: any) => {
      if (record.id) {
        // If the record has an ID, it should be updated
        return {
          id: record.id,
          kpi: record.kpi,
          sub_kpi: record.sub_kpi,
          date: record.date,
          fill_rate: record.fill_rate,
          other_fill_rate: record.other_fill_rate,
          company: record.company || null,
          customer: record.customer || null
        }
      } else {
        // If the record doesn't have an ID, it should be added as a new record
        return {
          kpi: record.kpi,
          sub_kpi: record.sub_kpi,
          date: record.date,
          fill_rate: record.fill_rate,
          other_fill_rate: record.other_fill_rate,
          company: record.company || null,
          customer: record.customer || null
        }
      }
    })
    const newRecords = updatedRecords.filter((record: any) => !record.id)
    const updatedExistingRecords = updatedRecords.filter((record: any) => record.id)

    //console.log({ updatedRecords });
    setSavePayload((prevSavePayload: any) => {
      return {
        ...prevSavePayload,
        new_record: getUniqueRecords([...(prevSavePayload?.new_record || []), ...newRecords]),
        update_record: getUniqueRecords([
          ...(prevSavePayload?.update_record || []),
          ...updatedExistingRecords
        ])
      };
    });

  }

  // console.log(savePayload)

  const handleCancelSave = () => {
    nav(`/kpi-report/view/${kpiData.id}`, {
      state: {
        selectedKpi: kpiData,
        kpiId: kpiData.id,
        date: date,
        selectedSubKpis: selectedSubKpis,
        company: selectedCompany,
        customer: selectedCustomer,
        year: selectedYear,
        companyName: selectedCompanyName,
        customerName: selectedCustomerName,
        kpiPermission: selectedKpiPermission
      }
    })
  }

  const handleSaveReport = () => {
    addOrEditReportData(savePayload).then((resp: any) => {
      nav(`/kpi-report/view/${kpiData.id}`, {
        state: {
          selectedKpi: kpiData,
          kpiId: kpiData.id,
          date: date,
          selectedSubKpis: selectedSubKpis,
          company: selectedCompany,
          customer: selectedCustomer,
          year: selectedYear,
          companyName: selectedCompanyName,
          customerName: selectedCustomerName,
          kpiPermission: selectedKpiPermission
        }
      })
    })
  }

  return (
    <>
      {" "}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 2,
          ml: 30,
          mr: 5,
          mt: 15,
          mb: 2,
          backgroundColor: "#F8F9FC"
        }}
      >
        <Grid container display={"flex"} flexDirection={"row"} sx={{ mt: 1 }}>
          <Grid item xs={3} display={"flex"} justifyContent={"flex-start"}>
            <h2>KPI Report</h2>
          </Grid>
          <Grid item xs={12}>
            {kpiData?.performance_indicator ? (
              <TextField
                margin="normal"
                fullWidth
                label="KPI"
                className="text-field"
                id="kpi"
                size="small"
                name="kpi"
                autoFocus
                aria-readonly
                value={kpiData?.performance_indicator}
                style={{ background: "#FFFFFF" }}
                InputProps={{
                  readOnly: true
                }}
              />
            ) : null}
          </Grid>

          <Grid item xs={1} display={"flex"} alignItems={"flex-start"} sx={{ mt: 2 }}>
            <InputLabel>Sub KPI</InputLabel>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ p: 2, border: '1px solid #AFAF9B', borderRadius: "3px", background: "#FFFFFF" }}>
              <Typography>
                <Grid container spacing={2} alignItems="center">
                  {selectedSubKpis && selectedSubKpis?.length
                    ? selectedSubKpis?.map((subKpi: any, index: any) => (
                      <Grid item xs={4} key={index}>
                        <Typography
                          sx={{ mt: 1, mb: 1 }}
                          style={{
                            cursor: "pointer",
                            borderRadius: 4,
                            color: "#FFFFFF",
                            backgroundColor: "#000099"
                          }}
                        >
                          <TickIcon /> {subKpi.name}
                          {
                            <Tooltip title={
                              <>
                                <div>
                                  <b>Sub KPI Name: {subKpi.name}</b>
                                </div>
                                <br />
                                <div>
                                  Type: {getSubKpiType(subKpi.type)}
                                </div>
                                <br />
                                <div>
                                  Data Type: {getSubKpiDataType(subKpi.data_type)}
                                </div>
                                <br />
                                <div>
                                  Description: {subKpi.explanatory_text}
                                </div>
                              </>
                            }>
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          }
                        </Typography>
                      </Grid>
                    ))
                    : null}
                </Grid>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <TextField
              margin="normal"
              fullWidth
              label="Financial Year"
              className="text-field"
              id="kpi"
              size="small"
              name="kpi"
              autoFocus
              aria-readonly
              value={date && date.startDate && date.endDate && date?.startDate?.slice(0, 4) + "-" + date?.endDate?.slice(2, 4) || ""}
              style={{ background: "#FFFFFF" }}
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          {selectedCompany ? (
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                margin="normal"
                fullWidth
                label="Company"
                multiline
                className="text-field"
                id="select-company"
                size="small"
                name="company"
                autoFocus
                aria-readonly
                value={selectedCompanyName}
                style={{ background: "#FFFFFF" }}
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          ) : null}
          {selectedCustomer ? (
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                margin="normal"
                fullWidth
                label="Customer"
                multiline
                className="text-field"
                id="customer"
                size="small"
                name="customer"
                autoFocus
                aria-readonly
                value={selectedCustomerName}
                style={{ background: "#FFFFFF" }}
                InputProps={{
                  readOnly: true
                }}
              />
            </Grid>
          ) : null}
          <Grid item xs={2} display={"flex"} justifyContent={"flex-start"}>
            <Typography sx={{ fontWeight: 500, fontSize: "16px", mt: 2 }}>
              Report Name
            </Typography>
          </Grid>
          {selectedSubKpis &&
            selectedSubKpis?.length &&
            selectedSubKpis?.sort((a: any, b: any) => a.data_type - b.data_type).map((subKpi: any, index: any) => {
              const useCompany = isCompanyUser(userType) &&
                (subKpi?.type === subKpiType[1].id || subKpi?.type === subKpiType[2].id)
                && selectedCompany;

              return (
                <CreateKpiReportData
                  selectedKpi={kpiData}
                  kpiId={kpiData.id}
                  key={subKpi.id}
                  subKpi={subKpi}
                  date={date}
                  company={selectedCompany}
                  customer={selectedCustomer}
                  useCompany={useCompany}
                  reportData={reportData}
                  handleSaveEditRow={handleSaveEditRow}
                  index={subKpi.id}
                  isActive={activeChild === subKpi.id}
                  handleToggle={handleToggle}
                />
              )
            })}
          <Grid container display={"flex"} sx={{ mt: 2, mb: 4 }}>
            <Grid
              item
              xs={6}
              sx={{ pr: 1 }}
              justifyContent={"flex-end"}
              display={"flex"}
            >
              <Button
                onClick={handleCancelSave}
                variant="contained"
                style={{ background: "#F8F9FC", color: "#000000" }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} display={"flex"}>
              <Button
                onClick={handleSaveReport}
                variant="contained"
                style={{ background: "#000099" }}
                disabled={!kpiData || !selectedSubKpis.length || !selectedYear}
                classes={{ disabled: "custom-disabled-button" }}
              >
                Save Report
              </Button>{" "}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default CreateKpiReport

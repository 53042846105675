import { useLocation, useNavigate } from "react-router-dom"
import { ReactComponent as AppLogo } from "../../assests/images/AppLogo.svg"
import { ReactComponent as SuccessIcon } from "../../assests/images/success.svg"
import {
  Box,
  Button,
  Grid,
  Paper,
  ThemeProvider,
  createTheme
} from "@mui/material"
import { useEffect, useState } from "react"
import "../SignIn/SignIn.css"

const EmailVerifySuccess = () => {
  const nav = useNavigate()
  const location = useLocation()
  const [email, setEmail] = useState("")
  const theme = createTheme({
    palette: {
      secondary: {
        main: "#000099"
      }
    }
  })

  useEffect(() => {
    setEmail(location.state?.email)
  }, [])

  const NavigateToAddProfile = async (event: any) => {
    nav("/add-profile", {
      state: { email }
    })
  }

  return (
    <>
      <div className="page-container">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            px: 2
          }}
        >
          <Paper
            sx={{
              p: 2,
              textAlign: "center",
              maxWidth: "400px",
              width: "100%"
            }}
            style={{
              borderColor: "#000099",
              borderWidth: 2,
              borderStyle: "solid",
              borderRadius: 8
            }}
          >
            <div className="grid-container">
              <Grid
                container
                spacing={2}
                direction={"column"}
                justifyItems={"center"}
                alignItems={"center"}
              >
                <Grid item xs={2}>
                  <AppLogo />
                  <Box sx={{ m: 2, fontSize: "24px", fontWeight: 500 }}>
                    Email is Successfully Verified
                  </Box>
                  <SuccessIcon />
                </Grid>

                <Grid item xs={12}>
                  <ThemeProvider theme={theme}>
                    <Button
                      variant={"contained"}
                      color="secondary"
                      onClick={NavigateToAddProfile}
                      style={{
                        width: 320,
                        color: "#FFFFFF",
                        textTransform: "none"
                      }}
                    >
                      Get started
                    </Button>
                  </ThemeProvider>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Box>
      </div>
    </>
  )
}

export default EmailVerifySuccess
